// BarChart.js
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./../css/style.css";

const BarChart = ({
  item,
  data,
  xAxis,
  yAxis,
  type,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const svgRef = useRef();
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const margin = 80;
      const w = item?.width;
      const h = 200;

      const dimensions = {
        width: 600,
        height: 600,
      };

      const margins = {
        left: 100,
        top: 20,
        right: 20,
        bottom: 100,
      };

      const innerDimensions = {
        width: dimensions.width - margins.left - margins.right,
        height: dimensions.height - margins.top - margins.bottom,
      };

      const svg = d3
        .select(svgRef.current)
        .attr("width", w)
        .attr("height", h)
        .style("overflow", "visible");
      //.style('margin-top', '75px');

      svg.append("g").attr("transform", `translate(${margin}, ${margin})`);

      const xScale = d3
        .scaleBand()
        .domain(data.map((d) => d[item?.x_cord]))
        .range([0, w])
        .padding(0.4);

      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d[item?.y_cord])])
        .range([h, 0]);

      const xaxis = d3
        .axisBottom(xScale)
        .tickValues(data.map((d) => d[item?.x_cord]))
        .tickFormat((d) => d);

      svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0, ${h})`)
        .call(xaxis);

      const yaxis = d3.axisLeft(yScale);
      svg.append("g").attr("class", "y-axis").call(yaxis);

      svg
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft().scale(yScale).tickSize(-w, 0, 0).tickFormat(""));

      // Customize x-axis ticks
      svg
        .selectAll(".x-axis text")
        .attr("transform", "rotate(-45)")
        .attr("text-anchor", "end")
        .attr("dx", "-0.5em")
        .attr("dy", "0.5em");

      // Customize y-axis ticks
      svg.selectAll(".y-axis text").attr("font-size", "10px");

      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip-dashboards");

      svg
        .append("text")
        .attr("class", "x-axis-label")
        .attr("x", w / 2)
        .attr("y", h + 80)
        .attr("text-anchor", "middle")
        .text(item?.x_axis_label);

      svg
        .append("text")
        .attr("class", "y-axis-label")
        .attr("x", -h / 2)
        .attr("y", -50)
        .attr("text-anchor", "middle")
        .attr("transform", "rotate(-90)")
        //.selectAll('tspan')
        //.data(['Estimated sum of', 'visitors during', 'observation period'])
        //.enter()
        //.append('tspan')
        //.attr('x', 0) // Center the text
        //.attr('dy', (d, i) => i + 20) // Adjust line spacing
        .text(item?.y_axis_label);

      svg
        .selectAll(".bar")
        .data(item?.data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", (d) => xScale(d[item?.x_cord]))
        .attr("y", (d) => yScale(d[item?.y_cord]))
        .attr("width", xScale.bandwidth())
        .attr("height", (d) => h - yScale(d[yAxis]))
        .attr("fill", `${item?.color}`)
        .on("mouseover", function (event, d) {
          const currentColor = d3.select(this).attr("fill");
          const lightenedColor = d3
            .color(currentColor)
            .brighter(-0.9)
            .toString();

          d3.select(this)
            .transition()
            .duration(200)
            .attr("fill", lightenedColor);

          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip
            .html(
              `${item?.x_axis_label}: ${d[item?.x_cord]}<br>${
                item?.y_axis_label
              }: ${d[item?.y_cord]}`
            )
            .style("left", event.pageX + 5 + "px")
            .style("top", event.pageY - 28 + "px");
        })
        .on("mouseout", function (event, d) {
          d3.select(this)
            .transition()
            .duration(200)
            .attr("fill", `${item?.color}`);

          tooltip.transition().duration(500).style("opacity", 0);
        });
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [data, isLoading]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div className="mt-4"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div
          style={{
            overflow: "visible",
            margin: "95px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg ref={svgRef}></svg>
        </div>
      )}
    </>
  );
};

export default BarChart;
