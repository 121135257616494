import React from 'react'
import { useEffect,useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { Context } from '../../contextApi/context';
import axios from 'axios';
import UserService from '../../services/UserService';
import "../css/mapmetadata.css";
import Schema from './Schema.js';
import Notification from '../../common/notification/Notification.js';

const serverConfig = require("../../config/server.js");

const DatabaseService = () => {
  const {selectedOrganization} = useContext(Context);
  const {isDarkTheme} = useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  
  const [dbTypeLookup, setDBTypeLookup] = useState([]);
  const [formData, setFormData] = useState({});
  const [dbType, setDbType] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [refreshDBServiceList, setRefreshDBServiceList] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    //set database type dropdown
    axios.post(serverConfig.api_base_url + "dwh_dbtype_list_get",{token:token, org:selectedOrganization?.slug})
    .then(response=>{
      console.log("dwh_dbtype_list_get:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("dwh_dbtype_list_get data:", response.data.items);
          setDBTypeLookup(response.data.items);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      }
    })
    .catch(err=>console.log("error is",err));
  }, [token, selectedOrganization]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    
    var x = event.target.closest('.more-options-wrapper').querySelector('.more-options');
    if (x.style.display === "none") {
      for(let i=0; i<el.length; i++){
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    
    if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
      console.log('selectedOrganization', selectedOrganization)
      console.log('token', token);
      /* dwh_dbs_list_get */
      axios.post(serverConfig.api_base_url + "dwh_dbs_list_get",{token:token, org:selectedOrganization?.slug})
      .then(response=>{
        console.log("dwh_dbs_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          if (items !== null || items !== undefined){
            console.log(response.data.items);
            let appDataArray = response.data.items;
            let appData = [];

            console.log(appDataArray);
            appDataArray && appDataArray.reverse().map((item, index) => {
              let noOfActions = Number(item.is_editable) + Number(item.is_deletable) + Number(item.is_validated === 0 ? 1 : 0);
              const Actions = () => <>
                {item.is_editable === 1 && <Button variant='text' onClick={() => handleShowEditDBServiceModal(item)} title={noOfActions > 2 ? '' : 'Edit'}><i className="fa-solid fa-pencil not-0" aria-hidden="true"></i> {noOfActions > 2 && <span>Edit</span>}</Button>}
                {item.is_deletable === 1 && <Button variant='text' onClick={() => handleShowDeleteDBServiceModal(item?.dbs, item?.name)} title={noOfActions > 2 ? '' : 'Delete'}><i className="fa-solid fa-trash-can text-red-500" aria-hidden="true"></i> {noOfActions > 2 && <span>Delete</span>}</Button>}
                {item.is_validated === 0 && <Button variant='text' onClick={(e) => handleValidateDBService(item?.dbs, e)} title={noOfActions > 2 ? '' : 'Validate'}><i className="fa-solid fa-file-circle-check not-0"></i> {noOfActions > 2 && <span>Validate</span>}</Button>}
              </>;
              {item.actions = (noOfActions > 2 ? <div className="text-right more-options-wrapper">
                {(item.is_editable === 1 || item.is_deletable === 1 || item.is_validated === 0) && <button type="button" onClick={handleShowMoreOptions} title="More" className='btn-more-options'><i className="fa-solid fa-ellipsis-vertical not-0"></i></button>}
                <div className="more-options" style={{display: "none"}}>
                  <Actions />
                </div>
              </div> : <div className="flex items-center justify-end gap-3">
                <Actions />
              </div>)}
              appData.push(item);
            });
            console.log(appData);
            setTableData(appData);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch(err=>console.log("error is",err));
    }
    
  }, [token, selectedOrganization, refreshDBServiceList]);

  const sortDown=<i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp=<i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ['Updated', 'Option 2', 'Option 3'];
  const sortingIcons= [sortDown,sortUp];

  //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

  const columns = [
    {
      name: <div className='font-bold text-sm'>Database Services</div>,
      selector: row => <b className='text-sm'>{row.name}</b>,
      sortable: true,
      wrap: true,
      //width: '30%'
    },
    {
      name: <div className='font-bold text-sm'>Type</div>,
      selector: row => row.db_type,
      sortable: true,
      wrap: true,
      //width: '15%'
    },
    {
      name: <div className='font-bold text-sm'>Description</div>,
      selector: row => row.description,
      sortable: true,
      wrap: true,
      //width: '30%'
    },
    {
      name: <div className='font-bold text-sm'>Validated</div>,
      selector: row => row.is_validated,
      cell: (row) => row.is_validated === 1 ? <div className='status green'><i className="fa-regular fa-circle-check"></i></div> : <div className='status red'><i className="fa-regular fa-circle-xmark"></i></div>,
      sortable: true,
      wrap: true,
      //width: '10%'
    },
    {
      name: <div className='font-bold text-sm'>Actions</div>,
      selector: row => row.actions,
      //width: 'calc(15% - 48px)'
    },
  ];

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }
  
  //Create
  const [createDBServiceModalShow, setCreateDBServiceModalShow] = useState(false);
  const handleCloseCreateDBServiceModal = () => {
    setCreateDBServiceModalShow(false);
    setFormMessage({});
    setFormData({});
    setDbType({});
    setErrorMsg("");
  }
  const handleShowCreateDBServiceModal = () => {
    setCreateDBServiceModalShow(true);
  }

  const handleCreateDBServiceSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_dbs_create",{
      token:token,
      org:selectedOrganization?.slug,
      name: formData.name,
      description: formData.description ? formData.description : "-",
      url: formData.url,
      agent_key: formData.agent_key,
      db_type: formData.db_type
    })
    .then(response=>{
      console.log("dwh_dbs_create:", response);
      if (response.data.status === 200) {
        setFormData({});
        setDbType({});
        setFormMessage({message:"Database Service created successfully", type:"success"});
        setRefreshDBServiceList(!refreshDBServiceList);
        handleCloseCreateDBServiceModal();
        handleShowAlertModal('Database Service created successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Edit DB Service
  const [dbServiceSlug, setDBServiceSlug] = useState("");
  const [dbServiceName, setDBServiceName] = useState("");
  const [editDBServiceModalShow, setEditDBServiceModalShow] = useState(false);
  const handleCloseEditDBServiceModal = () => {
    setEditDBServiceModalShow(false);
    setDBServiceSlug("");
    setDBServiceName("");
    setFormMessage({});
    setFormData({});
    setDbType({});
    setErrorMsg("");
  }
  const handleShowEditDBServiceModal = (details) => {
    console.log(details);
    setFormData(details);
    setDbType({label: details.db_type, value: details.db_type});
    setEditDBServiceModalShow(true);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleUpdateDBService = (event) => {
    event.preventDefault();
    console.log(formData);
    axios.post(serverConfig.api_base_url + "dwh_dbs_update",{
      token:token,
      dbs:formData.dbs,
      name: formData.name,
      description: formData.description ? formData.description : "-",
      url: formData.url,
      //agent_key: formData.agent_key,
      db_type: formData.db_type
    })
    .then(response=>{
      console.log("dwh_dbs_update:", response);
      if (response.data.status === 200) {
        setFormData({});
        setDbType({});
        setFormMessage({message:"Database Service updated successfully", type:"success"});
        setRefreshDBServiceList(!refreshDBServiceList);
        handleCloseEditDBServiceModal();
        handleShowAlertModal('Database Service updated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Delete DB Service
  const [deleteDBServiceModalShow, setDeleteDBServiceModalShow] = useState(false);
  const handleCloseDeleteDBServiceModal = () => {
    setDeleteDBServiceModalShow(false);
    setDBServiceSlug("");
    setDBServiceName("");
    setFormMessage({});
    setFormData({});
  }
  const handleShowDeleteDBServiceModal = (dbsSlug, dbsName) => {
    setDeleteDBServiceModalShow(true);
    setDBServiceSlug(dbsSlug);
    setDBServiceName(dbsName);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  }

  const handleDeleteDBService = (event) => {
    event.preventDefault();
    axios.post(serverConfig.api_base_url + "dwh_dbs_delete",{token:token, dbs:dbServiceSlug})
    .then(response=>{
      console.log("dwh_dbs_delete:", response);
      if (response.data.status === 200) {
        setFormMessage({message:"Database Service deleted successfully", type:"success"});
        setRefreshDBServiceList(!refreshDBServiceList);
        handleCloseDeleteDBServiceModal();
        handleShowAlertModal('Database Service deleted successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  //Validate DB Service
  /* const [validateDBServiceModalShow, setValidateDBServiceModalShow] = useState(false);
  const handleCloseValidateDBServiceModal = () => {
    setValidateDBServiceModalShow(false);
    setDBServiceSlug("");
    setDBServiceName("");
    setFormMessage({});
    setFormData({});
  }
  const handleShowValidateDBServiceModal = (dbsSlug, dbsName) => {
    setValidateDBServiceModalShow(true);
    setDBServiceSlug(dbsSlug);
    setDBServiceName(dbsName);

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }
  } */

  const handleValidateDBService = (dbServiceSlug, event) => {
    event.preventDefault();

    let el = document.querySelectorAll('.more-options-wrapper .more-options');
    for(let i=0; i<el.length; i++){
      el[i].style.display = "none";
    }

    axios.post(serverConfig.api_base_url + "dwh_dbs_validate",{token:token, dbs:dbServiceSlug})
    .then(response=>{
      console.log("dwh_dbs_validate:", response);
      if (response.data.status === 200) {
        setFormMessage({message:"Database Service validated successfully", type:"success"});
        setRefreshDBServiceList(!refreshDBServiceList);

        setDBServiceSlug("");
        setDBServiceName("");
        setFormMessage({});
        setFormData({});

        //handleCloseValidateDBServiceModal();
        handleShowAlertModal('Database Service validated successfully');
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
        setFormMessage({});
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleFetchKey = () => {
    console.log(formData.url);
    if(formData.url){
      axios.post(serverConfig.api_base_url + "dwh_dbs_fetch_key",{token: token, org:selectedOrganization?.slug, url:formData.url})
      .then(response=>{
        console.log("dwh_dbs_fetch_key:", response);
        if (response.data.status === 200) {
          console.log(response.data.data);
          setFormData({...formData, agent_key:response.data.data.key2, db_type:response.data.db_type});
          setDbType({label: response.data.db_type, value: response.data.db_type});
          setErrorMsg("");
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          //handleShowAlertModal(response.data.message, true);
          setErrorMsg(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  return(
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-dbs table-mapmetadata mt-4 ">
        <div className="flex justify-between items-center space-x-4">
          <h1 className='font-semibold text-2xl mt-4 mb-4'>Data Entities</h1>
          {tableData.length > 0 && <div className="flex items-center space-x-4 text-black">
            {/* <div className='flex items-center gap-12 mt-4'>
              <div className="relative">
                <input type="text" placeholder="Search" className="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input" />
                <div className="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                  <i className=" text-sm fas fa-search"></i>
                </div>
              </div>
            </div>
            <div className="relative inline-block">
              <div className=" h-8 md:h-10 px-3 bg-white border border-gray-300 rounded-md focus:outline-none appearance-none search-input text-style flex items-center">
                <div className='pr-5'>
                  <i className="fa-solid fa-arrow-down-short-wide text-sm" ></i>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                <i className="fa-solid fa-chevron-down text-sm"></i> 
              </div>
            </div> */}
            <button type='button' className='sub-grp px-3 py-2' onClick={handleShowCreateDBServiceModal}><i className="fa-solid fa-plus mr-2" aria-hidden="true"></i> Create Database Service</button>
          </div>}
        </div>
        {tableData.length > 0 ? <DataTable
          columns={columns}
          data={tableData}
          expandableRows
          //expandableRowsComponent={({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>}
          expandableRowsComponent={({ data }) => <Schema dbsSlug={data.dbs} />}
          responsive={true}
          pagination={tableData.length > 10 ? true : false}
          highlightOnHover
          customStyles={{
            rows: {
              style: {
                minHeight: '48px',
                border: '2px solid var(--neutral-400)',
                borderRadius: '10px',
                marginTop: '10px',
              },
              highlightOnHoverStyle: {
                color: isDarkTheme ? '#ffffff' : '#000000',
                backgroundColor: isDarkTheme ? '#222222' : '#EFF3F9',
                outline: 'none'
              }
            },
            expanderButton: {
              style: {
                borderRadius: '8px',
              }
            }
          }}
          /* subHeader subHeaderComponent={<div className="flex items-center justify-between" style={{position: "absolute", left: 0, top: "55px", zIndex: 9, width: "100%"}}><span className='font-semibold text-xs'>Database Services</span><span className="text-right" style={{width: "15%", paddingRight: "16px"}}><Button variant='text btn-create' title="Create DB Service" onClick={handleShowCreateDBServiceModal}><i className="fa-solid fa-plus not-0" aria-hidden="true"></i></Button></span></div>} subHeaderAlign="left" */
        /> : <div className='flex flex-col h-3/4 items-center justify-center'>
        <div className='mb-8'><img src={require('./../../Asset/Images/no-records.png')} alt="No records" /></div>
        <p><b>This Organisation has no Data Entities</b></p>
        <p className='text-sm'>Create first Database Service for {selectedOrganization?.name}</p>
        <div className='mt-5'><button className='sub-grp px-3 py-2' onClick={handleShowCreateDBServiceModal}>New Database Service</button></div>
      </div>}

        {createDBServiceModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Create Database Service</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleCreateDBServiceSubmit}>
              <div className='scrollable mt-5 mb-5'>
                <div className='mb-1'>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Description</label>
                    <textarea value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>URL<span className=' text-red-500'>*</span></label>
                    <div className='flex mt-1'>
                      <input type="url" value={formData.url || ""} onChange={(e)=>  {setFormData({ ...formData, url: e.target.value, agent_key: null}); setErrorMsg("");}} placeholder='URL' className='custom-input py-2 px-2 text-left' required/>
                      <button type="button" className="btn-popup ml-2 px-2 rounded" onClick={handleFetchKey} disabled={!formData.url}>Fetch Key</button>
                    </div>
                  </div>
                  {errorMsg && <div className='text-red-500 mt-1'>{errorMsg}</div>}
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Agent Key<span className=' text-red-500'>*</span></label>
                    <input type="text" value={formData.agent_key || ""} onChange={(e)=>  setFormData({ ...formData, agent_key: e.target.value})} placeholder='Agent Key' className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Database Type<span className='text-red-500'>*</span></label>
                    <Select value={dbType || ""} classNamePrefix="select" placeholder="Database Type" options={dbTypeLookup} onChange={(option)=>  {setFormData({ ...formData, db_type: option.value}); setDbType(option);}} required  />
                  </div>
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseCreateDBServiceModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Save</button>
              </div>
            </form>
          </div>
        </div>}

        {editDBServiceModalShow && <div className='overlay'>
          <div className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2'>
            <div className='flex justify-between items-center gap-5'>
              <div style={{fontSize:16}}>Edit Database Service</div>
            </div>
            <form className='flex flex-col justify-between w-full' onSubmit={handleUpdateDBService}>
              <div className='scrollable mt-5 mb-5'>
                <div>
                  <div className='flex flex-col gap-1 text-xs'>
                    <label>Name<span className=' text-red-500'>*</span></label>
                    <input type="text" value={formData.name || ""} onChange={(e)=>  setFormData({ ...formData, name: e.target.value})} placeholder='Name' className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Description</label>
                    <textarea value={formData.description || ""} onChange={(e)=>  setFormData({ ...formData, description: e.target.value})} placeholder='Description' className='custom-input py-2 px-2 text-left mt-1'></textarea>
                  </div>
                  {/* <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>URL</label>
                    <input type="text" value={formData.url || ""} onChange={(e)=>  setFormData({ ...formData, url: e.target.value})} placeholder='URL' className='custom-input py-2 px-2 text-left mt-1'/>
                  </div>
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Agent Key</label>
                    <input type="password" value={formData.agent_key || ""} onChange={(e)=>  setFormData({ ...formData, agent_key: e.target.value})} placeholder='Agent Key' className='custom-input py-2 px-2 text-left mt-1'/>
                  </div> */}

                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>URL<span className=' text-red-500'>*</span></label>
                    <div className='flex mt-1'>
                      <input type="url" value={formData.url || ""} onChange={(e)=>  {setFormData({ ...formData, url: e.target.value, agent_key: null}); setErrorMsg("");}} placeholder='URL' className='custom-input py-2 px-2 text-left' required/>
                      <button type="button" className="btn-popup ml-2 px-2 rounded" onClick={handleFetchKey} disabled={!formData.url}>Fetch Key</button>
                    </div>
                  </div>
                  {errorMsg && <div className='text-red-500 mt-1'>{errorMsg}</div>}
                  <div className='flex flex-col gap-1 mt-3 mb-5 text-xs'>
                    <label>Agent Key<span className=' text-red-500'>*</span></label>
                    <input type="text" value={formData.agent_key || ""} onChange={(e)=>  setFormData({ ...formData, agent_key: e.target.value})} placeholder='Agent Key' className='custom-input py-2 px-2 text-left mt-1' required/>
                  </div>

                  <div className='flex flex-col gap-1 mt-3 text-xs'>
                    <label>Database Type<span className=' text-red-500'>*</span></label>
                    <Select value={dbType || ""} classNamePrefix="select" placeholder="Database Type" options={dbTypeLookup} onChange={(option)=>  {setFormData({ ...formData, db_type: option.value}); setDbType(option);}} required  />
                  </div>
                </div>
              </div>
              {/* bottom buttom */}
              <div className='spacing-div'>
                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseEditDBServiceModal}>Cancel</button>
                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Update</button>
              </div>
            </form>
          </div>
        </div>}

        {deleteDBServiceModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={handleDeleteDBService}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Delete Database Service</div>
              </div>
      
              <div className='mt-4'>
                <p>Are you sure you want to delete Database Service <b>{dbServiceName}</b> ?</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseDeleteDBServiceModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Delete</button>
            </div>
          </form>
        </div>}

        {/* {alertModalShow && <div className='overlay alert'>
          <div className='modalPopup rounded p-2 pl-3 relative my-2 text-custom text-sm flex justify-between flex-col w-2/3'>
            <div className=' flex-grow overflow-y-auto'>
              <div className='flex justify-between items-center gap-5'>
                <div className='flex-auto text-center'>{alertMessage}</div>
                {showOkButton && <button type="button" className='btn-popup px-4 py-1 ml-3 rounded-lg text-sm text-medium' onClick={handleCloseAlertModal}>OK</button>}
              </div>
            </div>
          </div>
        </div>} */}
        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}

        {/* {validateDBServiceModalShow && <div className='overlay'>
          <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={handleValidateDBService}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:16}}>Validate Database Service</div>
              </div>
      
              <div className='mt-4'>
                <p>Are you sure you want to validate Database Service <b>{dbServiceName}</b> ?</p>
              </div>
            </div>
            <div className='spacing-div'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={handleCloseValidateDBServiceModal}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium'>Validate</button>
            </div>
          </form>
        </div>} */}
      </div>
    </div>
  );
}

export default DatabaseService;