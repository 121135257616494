import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const TableChart = ({
  data,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const tableRef = useRef();
  const [sortedData, setSortedData] = useState(data);
  const [columns, setColumns] = useState([]);
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    column: null,
    direction: "asc",
  });
  const [frozenColumns, setFrozenColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [title, setTitle] = useState("   ");
  const entriesPerPage = 20; // Number of entries per page
  const totalPages = Math.ceil(sortedData.length / entriesPerPage);

  useEffect(() => {
    if (data.length > 0 && data !== "fetching") {
      // Use the data_sequence from item to set columns
      setColumns(item.data_sequence);

      setSortedData(data);

      const pageData = data.slice((currentPage - 1) * 20, currentPage * 20);
      setPaginatedData(pageData);
    } else if (data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [data, currentPage, item]);

  useEffect(() => {
    renderTable();
  }, [columns, frozenColumns, isLoading, sortConfig, paginatedData]);

  const handleSort = (column) => {
    const newDirection =
      sortConfig.column === column && sortConfig.direction === "asc"
        ? "desc"
        : "asc";
    setSortConfig({ column, direction: newDirection });

    const sortedArray = [...sortedData].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });

    setSortedData(sortedArray);
    setPaginatedData(
      sortedArray.slice((currentPage - 1) * 20, currentPage * 20)
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleFreezeColumn = (column) => {
    setFrozenColumns(frozenColumns.includes(column) ? [] : [column]);
  };

  const handleCopyValues = (column) => {
    const values = sortedData.map((row) => row[column]).join("\n");
    navigator.clipboard.writeText(values).then(() => {
      alert(`Copied values of column "${column}" to clipboard.`);
    });
  };

  const renderTable = () => {
    d3.select(tableRef.current).selectAll("*").remove();

    const table = d3
      .select(tableRef.current)
      .append("table-chart")
      .attr("class", "table-chart");

    const thead = table.append("thead").append("tr");

    thead
      .selectAll("th")
      .data(columns)
      .enter()
      .append("th")
      .attr("class", "sortable")
      .each(function (column) {
        const th = d3.select(this);

        const container = th.append("div").attr("class", "th-container");

        container.append("span").text(column.replace(/_/g, " ").toUpperCase());

        const sortIconContainer = container
          .append("span")
          .attr("class", "sort-icon-container")
          .on("click", (event) => {
            event.stopPropagation();
            handleSort(column);
          });

        sortIconContainer
          .append("span")
          .attr("class", "sort-icon")
          .text(
            sortConfig.column === column
              ? sortConfig.direction === "asc"
                ? "↑"
                : "↓"
              : "↕"
          );
      });

    const tbody = table.append("tbody");

    const rows = tbody.selectAll("tr").data(paginatedData).enter().append("tr");

    const tableHeaders = table.select("thead").selectAll("th");
    tableHeaders.style("position", (d) =>
      frozenColumns.includes(d) ? "sticky" : "static"
    );
    tableHeaders.style("left", (d) => {
      const index = frozenColumns.indexOf(d);
      return index !== -1 ? `${index * 100}px` : "";
    });
    tableHeaders.style("background-color", (d) =>
      frozenColumns.includes(d) ? "#f0f0f0" : ""
    );

    const cells = rows
      .selectAll("td")
      .data((row) => columns.map((column) => ({ column, value: row[column] })))
      .enter()
      .append("td")
      .text((d) => d.value);

    const getLeft = (column) => {
      const index = frozenColumns.indexOf(column);
      if (index === -1) return "";
      const totalWidth = widths.slice(0, index).reduce((a, b) => a + b, 0);
      return `${totalWidth}px`;
    };

    cells.style("position", (d) =>
      frozenColumns.includes(d.column) ? "sticky" : "static"
    );
    cells.style("left", (d) => getLeft(d.column));
    tableHeaders.style("left", (d) => getLeft(d));
    cells.style("background-color", (d) =>
      frozenColumns.includes(d.column) ? "#f0f0f0" : ""
    );
  };

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  const downloadCSV = () => {
    const headers = columns.join(",");
    const rows = sortedData
      .map((row) => columns.map((column) => `"${row[column]}"`).join(","))
      .join("\n");

    const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title" style={{ padding: "12px" }}>
            {noDataFound}
          </div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-header">
              <div className="chart-title">{item?.title}</div>
              <button
                type="button"
                onClick={downloadCSV}
                className="export-csv-button"
                title="Export CSV"
              >
                <i className="fa-solid fa-share-square"></i>
              </button>
            </div>
          ) : (
            <div className="mt-4"></div>
          )}

          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
                <button type="button" onClick={downloadCSV}>
                  <i className="fa-solid fa-file-csv"></i>{" "}
                  <span>Export CSV</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <>
          <div className="table-chart-container" ref={tableRef}></div>
          {data.length > 20 && (
            <div className="pagination-controls">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                &lt;
              </button>
              <span className="page-number">
                {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={paginatedData.length < 20}
              >
                &gt;
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TableChart;
