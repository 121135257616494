import React from "react";
import { useEffect, useState, useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Context } from "../../contextApi/context.js";
import axios from "axios";
import { useNavigate } from "react-router";
import UserService from "../../services/UserService.js";
import "../css/adminDashboard.css";
import { useLocation } from "react-router-dom";
import BarChart from "../charts/BarChart.js";
import PieChart from "../charts/PieChart.js";
import NumberCard from "../charts/NumberCard.js";
import TableChart from "../charts/TableChart.js";
import VectorMap from "../charts/VectorMap.js";
import Map from "../charts/Map.js";
import MultipleBarChart from "../charts/MultipleBarChart.js";
import LineChart from "../charts/LineChart.js";
import RCMultiLineChart from "../charts/RCMultiLineChart.js";
import MultiLineChart from "../charts/MultilineChart.js";
import { MultiSelect } from "react-multi-select-component";
import StackedBarChart from "../charts/StackedBarChart.js";
import { map } from "d3";
import SubTitle from "../charts/SubTitle.js";
import RCStackedBarChart from "../charts/RCStackedBarChart.js";
import RCPercentAreaChart from "../charts/RCPercentAreaChart.js";
import RCBarChart from "../charts/RCBarChart.js";
import AdjustableChart from "../charts/AdjustableChart.js";
import Legend from "../charts/Legend.js";
import RCMultipleBarChart from "../charts/RCMultipleBarChart.js";

const serverConfig = require("../../config/server.js");

const ManageLayoutTable = () => {
  const navigate = useNavigate();

  const { selectedOrganization, isDarkTheme, setActiveSubmenuOption } =
    useContext(Context);

  const [token, setToken] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [currPage, setCurrPage] = useState("manage_layout");
  const [openEditChartModal, setOpenEditChartModal] = useState(false);
  const [openEditChartAttributesModal, setOpenEditChartAttributesModal] =
    useState(false);
  const [openDeleteChartModal, setOpenDeleteChartModal] = useState(false);

  const [formData, setFormData] = useState({});
  const [editChartDetails, setEditChartDetails] = useState({});
  const [deleteChart, setDeleteChart] = useState(false);
  const [editChart, setEditChart] = useState(false);
  const [editChartAttributes, setEditChartAttributes] = useState({});
  const [templateGroupList, setTemplateGroupList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [chartList, setChartList] = useState([]);
  const [chartType, setChartType] = useState([]);
  const [datasetType, setDatasetType] = useState([]);
  const [chartAttributes, setChartAttributes] = useState([]);
  const [chartSlug, setChartSlug] = useState("");
  const [dashCharthartSlug, setDashChartSlug] = useState("");
  const [refreshTemplateGroupList, setRefreshTemplateGroupList] =
    useState(false);

  const location = useLocation();

  // Access the rowData from the state
  const rowData = location.state?.rowData;

  console.log(rowData);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };

  useEffect(() => {
    if (openEditChartModal === true) {
      handleCallChartList();
      axios
        .post(
          serverConfig.api_base_url +
            "canvas_layout_dashboard_chart_details_get",
          {
            token: token,
            org: selectedOrganization?.slug,
            dash_chrt: dashCharthartSlug,
            // chrt: chartSlug,
          }
        )
        .then((response) => {
          console.log(
            "canvas_layout_dashboard_chart_details_get response:",
            response
          );
          if (response.data.status === 200) {
            const chartDetails = response.data.items;
            setEditChartDetails(chartDetails);
            console.log(editChartDetails);

            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [openEditChartModal]);

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", {
        jwt: jwt,
        url: window.location.host,
      })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("token"));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    console.log("token", token);
    console.log(sessionStorage.getItem("token"));

    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);
      console.log("token", token);

      axios
        .post(serverConfig.api_base_url + "canvas_chart_template_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("canvas_chart_template_list_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            console.log(items);
            if (items !== null && items !== undefined) {
              console.log(response.data.items);
              //console.log(response.data.items.map(el => {delete el.name; return el}));
              console.log(
                response.data.items.map((el) => {
                  return { name: el.name, template: el.template };
                })
              );
              setTemplateList();
            } else {
              setTemplateList([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      // axios
      //   .post(serverConfig.api_base_url + "canvas_dataset_list_get", {
      //     token: token,
      //     org: selectedOrganization?.slug,
      //   })
      //   .then((response) => {
      //     console.log("canvas_dataset_list_get response:", response);
      //     if (response.data.status === 200) {
      //       let items = response.data.items;
      //       console.log(items);
      //       if (items !== null && items !== undefined) {
      //         console.log(response.data.items);
      //         //console.log(response.data.items.map(el => {delete el.name; return el}));
      //         console.log(
      //           response.data.items.map((el) => {
      //             return { name: el.name, template: el.template };
      //           })
      //         );
      //         setTemplateList();
      //       } else {
      //         setTemplateList([]);
      //       }
      //     } else if (response.data.status === 301) {
      //       handleLogout();
      //     } else {
      //       handleShowAlertModal(response.data.message, true);
      //       setTimeout(() => {
      //         handleCloseAlertModal();
      //       }, 6000);
      //     }
      //   })
      //   .catch((err) => console.log("error is", err));

      /* dwh_template_group_list_get */
      axios
        .post(serverConfig.api_base_url + "canvas_dashboard_get_list", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("canvas_dashboard_get_list response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            if (items !== null && items !== undefined) {
              console.log(response.data.items);
              setTemplateGroupList();
              let appDataArray = response.data.items;
              let appData = [];

              console.log(appDataArray);
              appDataArray &&
                appDataArray.reverse().map((item, index) => {
                  {
                    item.enabled = item.status;
                  }
                  {
                    item.status = (
                      <div className="flex flex-row">
                        {item.status === 0 ? (
                          <div className="bg-bdr bg-bdr-peach">
                            <div>
                              <i class="fa-regular fa-user"></i>
                            </div>
                            <div>Disabled</div>
                          </div>
                        ) : (
                          <div className="bg-bdr bg-bdr-green">
                            <div>
                              <i class="fa-regular fa-user"></i>
                            </div>
                            <div>Enabled</div>
                          </div>
                        )}
                      </div>
                    );
                  }

                  let noOfActions = 1 + 1;
                  const Actions = () => (
                    <>
                      <Button
                        title={noOfActions > 2 ? "" : "Edit"}
                        variant="text"
                        onClick={() => handleShowEditTemplateModal(item)}
                      >
                        <i
                          className="fa-solid fa-pencil not-0"
                          aria-hidden="true"
                        ></i>{" "}
                        {noOfActions > 2 && <span>Edit</span>}
                      </Button>
                      <Button
                        title={noOfActions > 2 ? "" : "Delete"}
                        variant="text"
                        onClick={() =>
                          handleShowDeleteTemplateModal(
                            item?.templateGrp,
                            item?.display_name
                          )
                        }
                      >
                        <i
                          className="fa-solid fa-trash-can text-red-500"
                          aria-hidden="true"
                        ></i>{" "}
                        {noOfActions > 2 && <span>Delete</span>}
                      </Button>
                    </>
                  );
                  {
                    item.actions =
                      noOfActions > 2 ? (
                        <div className="text-right more-options-wrapper">
                          <button
                            type="button"
                            onClick={handleShowMoreOptions}
                            title="More"
                            className="btn-more-options"
                          >
                            <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                          </button>
                          <div
                            className="more-options"
                            style={{ display: "none" }}
                          >
                            <Actions />
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-end gap-3">
                          <Actions />
                        </div>
                      );
                  }
                  appData.push(item);
                });
              console.log(appData);
              //   setTableData(appData);

              axios
                .post(serverConfig.api_base_url + "canvas_dataset_get_list", {
                  token: token,
                  org: selectedOrganization?.slug,
                  dash: rowData?.dash,
                  filter: null,
                })
                .then((response) => {
                  console.log(response.data.items, "canvas_dataset_get_list");
                  if(response.data.items !== null){
                    setChartData(response?.data?.items);
                  }
                  if (response.data.status === 200) {
                    if(response.data.items !== null){
                      response.data.items.map((item) => {
                        axios
                          .post(item?.url, {
                            fetch_instruction: item?.fetch_instruction,
                          })
                          .then((res) => {
                            console.log(res);
                            const updatedData = res.data.items;
                            console.log(item.cht, updatedData);
                            setChartData((prevData) => {
                              const existingItemIndex = prevData?.findIndex(
                                (dataItem) => dataItem?.cht === item?.cht
                              );
                              if (existingItemIndex !== -1) {
                                const newData = [...prevData];
                                newData[existingItemIndex] = updatedData;
                                return newData;
                              } else {
                                return [...prevData, updatedData];
                              }
                            });
                          })
                          .catch((err) => {
                            console.log(err);
                          })
                          .finally((fun) => {
                            //setIsLoading(false);
                          });
                      });
                    }
                  } else if (response.data.status === 526) {
                    setChartData(response.data.relay);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  } else if (
                    response.data.status >= 400 &&
                    response.data.status <= 499
                  ) {
                    handleShowAlertModal(response.data.message, true);
                    setTimeout(() => {
                      handleCloseAlertModal();
                    }, 6000);
                  }
                  //setFilterApply(false);
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              setTableData([]);
              setTemplateGroupList([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [
    token,
    selectedOrganization,
    refreshTemplateGroupList,
    deleteChart,
    editChart,
  ]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;
  const dropdownItems = ["Updated", "Option 2", "Option 3"];
  const sortingIcons = [sortDown, sortUp];

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  //Create
  const [createDashboardModalShow, setCreateDashboardModalShow] =
    useState(false);
  const [createNewChartModalShow, setCreateNewChartModalShow] = useState(false);
  const handleCloseDas = () => {
    setCreateDashboardModalShow(false);
    setFormData({});
  };

  const handleShowCreateTemplateModal = () => {
    setCreateDashboardModalShow(true);
    handleCallChartList();
  };

  const handleCallChartList = () => {
    axios
      .post(serverConfig.api_base_url + "canvas_chart_list_get", {
        token: token,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        console.log("canvas_chart_list_get response:", response);
        if (response.data.status === 200) {
          setChartList(response.data.items);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleNewChart = () => {
    setCreateNewChartModalShow(true);
    axios
      .post(serverConfig.api_base_url + "canvas_chart_template_list_get", {
        token: token,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        console.log("canvas_chart_template_list_get response:", response);
        if (response.data.status === 200) {
          setChartType(response.data.items);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));

    axios
      .post(serverConfig.api_base_url + "canvas_dataset_list_get", {
        token: token,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        console.log("canvas_dataset_list_get response:", response);
        if (response.data.status === 200) {
          setDatasetType(response.data.items);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const fetchChartData = (selectedValue) => {
    axios
      .post(
        serverConfig.api_base_url + "canvas_chart_template_attribute_list_get",
        {
          token: token,
          org: selectedOrganization?.slug,
          template: selectedValue,
          chrt: editChartDetails?.chrt,
        }
      )
      .then((response) => {
        console.log(
          "canvas_chart_template_attribute_list_get response:",
          response
        );
        if (response.data.status === 200) {
          setChartAttributes(response.data.items); // Set the chart attributes in the new state
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleCloseNewChartModal = () => {
    setCreateNewChartModalShow(false);
  };

  const handleCreateTemplateSubmit = (event) => {
    event.preventDefault();
    console.log(formData);

    const itemsPayload = {
      grid_column: formData.gridColumn,
      grid_row: formData.gridRow,
    };

    axios
      .post(serverConfig.api_base_url + "canvas_dashboard_chart_add", {
        token: token,
        org: selectedOrganization?.slug,
        dash: rowData?.dash,
        chrt: formData.selectedChart,
        items: itemsPayload,
      })
      .then((response) => {
        console.log("dwh_template_create:", response);
        if (response.data.status === 200) {
          setFormData({});
          setRefreshTemplateGroupList(!refreshTemplateGroupList);
          handleCloseDas();
          handleShowAlertModal("Dashboard created successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleCreateNewChartType = (event) => {
    event.preventDefault();

    // Prepare the attributes array in the correct format
    const attributesArray = chartAttributes.map((attribute) => ({
      name: attribute.name,
      value: attribute.value || "", // Ensure the value is an empty string if not provided
    }));

    // Prepare the payload with the desired structure
    const payload = {
      name: formData.name,
      description: formData.description,
      datast: formData.selectedDataset, // Assumed to be the correct key based on your structure
      chart_type: formData.selectedChartType,
      attributes: attributesArray, // Use the transformed attributes array
    };

    // Log the payload to verify the structure
    console.log("Payload:", payload);

    // Make the API call
    axios
      .post(serverConfig.api_base_url + "canvas_chart_create", {
        token: token,
        org: selectedOrganization?.slug,
        items: payload,
      })
      .then((response) => {
        console.log("canvas_chart_create:", response);
        if (response.data.status === 200) {
          // Clear the form and attributes if needed
          setFormData({});
          setChartAttributes([]);
          setRefreshTemplateGroupList(!refreshTemplateGroupList);
          handleCloseNewChartModal();
          handleCallChartList();
          handleShowAlertModal("Chart added successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleEditChartAttributes = () => {
    setOpenEditChartAttributesModal(true);

    axios
      .post(serverConfig.api_base_url + "canvas_chart_template_list_get", {
        token: token,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        console.log("canvas_chart_template_list_get response:", response);
        if (response.data.status === 200) {
          setChartType(response.data.items);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));

    axios
      .post(serverConfig.api_base_url + "canvas_dataset_list_get", {
        token: token,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        console.log("canvas_dataset_list_get response:", response);
        if (response.data.status === 200) {
          setDatasetType(response.data.items);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));

    axios
      .post(serverConfig.api_base_url + "canvas_layout_chart_details_get", {
        token: token,
        org: selectedOrganization?.slug,
        chrt: editChartDetails?.chrt,
        dash: rowData?.dash,
      })
      .then((response) => {
        console.log("canvas_layout_chart_details_get response:", response);
        if (response.data.status === 200) {
          const chartData = response?.data?.items;
          setEditChartAttributes(chartData);
          setChartAttributes(chartData?.attributes);
          console.log(editChartAttributes);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleCloseEditChartAttributesModal = () => {
    setOpenEditChartAttributesModal(false);
  };

  const handleUpdateChartAttributes = (e) => {
    e.preventDefault();

    // Prepare the attributes array in the correct format
    const attributesArray = chartAttributes.map((attribute) => ({
      name: attribute.name,
      value: attribute.value || "", // Ensure the value is an empty string if not provided
    }));

    console.log("EDIT CHART ATTRIBUTES", editChartAttributes);

    // Prepare the payload with the desired structure
    const payload = {
      name: editChartAttributes?.name,
      description: editChartAttributes?.description,
      datast: editChartAttributes?.datast, // Assumed to be the correct key based on your structure
      chart_type: editChartAttributes?.chart_type,
      attributes: attributesArray, // Use the transformed attributes array
    };

    console.log("PAYLOAD", payload);

    axios
      .post(serverConfig.api_base_url + "canvas_chart_update", {
        token: token,
        org: selectedOrganization?.slug,
        // dash_chrt: dashCharthartSlug,
        chrt: editChartDetails?.chrt,
        items: payload,
      })
      .then((response) => {
        console.log("canvas_chart_update :", response);
        if (response.data.status === 200) {
          // Clear the form and attributes if needed
          setChartAttributes([]);
          setOpenEditChartAttributesModal(false);
          handleShowAlertModal("Chart added successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleUpdateEditChart = (e) => {
    e.preventDefault();

    axios
      .post(serverConfig.api_base_url + "canvas_dashboard_chart_update", {
        token: token,
        org: selectedOrganization?.slug,
        dash_chrt: dashCharthartSlug,
        chrt: editChartDetails?.chrt,
        items: editChartDetails,
      })
      .then((response) => {
        console.log("canvas_dashboard_chart_update:", response);
        if (response.data.status === 200) {
          // Clear the form and attributes if needed
          setEditChart(!editChart);
          setFormData({});
          setEditChartDetails({});
          setChartAttributes([]);
          setOpenEditChartModal(false);
          handleShowAlertModal("Chart added successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleCloseDeleteChartModal = () => {
    setOpenDeleteChartModal(false);
  };

  const handleDeleteChart = (e) => {
    e.preventDefault();

    axios
      .post(serverConfig.api_base_url + "canvas_dashboard_chart_delete", {
        token: token,
        org: selectedOrganization?.slug,
        dash_chrt: dashCharthartSlug,
      })
      .then((response) => {
        console.log("canvas_dashboard_chart_delete :", response);
        if (response.data.status === 200) {
          // Clear the form and attributes if needed
          setDeleteChart(!deleteChart);
          setFormData({});
          setEditChartDetails({});
          setChartAttributes([]);
          setEditChartAttributes({});
          setOpenDeleteChartModal(false);
          handleShowAlertModal("Chart added successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  return (
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-mapmetadata mt-4 overflow-y-scroll overflow-x-scroll">
        <div
          className="flex justify-between items-center space-x-4"
          style={{
            "z-index": "999",
            /* position: "fixed", */
            position: "sticky",
            background: "white",
            /* width: "85%", */
            height: "60px",
            /* "margin-top": "-13px", */
            top: "-13px",
          }}
        >
          <h1 className="font-semibold text-2xl mt-4 mb-4">
            Manage Layout - {rowData?.name}
          </h1>
          {chartData?.length > 0 && (
            <div className="flex items-center space-x-4 text-black">
              <button
                type="button"
                className="sub-grp px-3 py-2"
                onClick={handleShowCreateTemplateModal}
              >
                <i className="fa-solid fa-plus mr-2" aria-hidden="true"></i> Add
                Chart
              </button>
            </div>
          )}
        </div>
        <div className="grid-container overflow-y-scroll overflow-x-scroll" style={{'marginTop':'20px'}}>
          {chartData?.length > 0 && (
            <>
              {chartData?.map((item) => {
                return (
                  <div
                    className="grid-item"
                    style={{
                      paddingBottom: "15px",
                      fontSize: "30px",
                      // height: "500px",
                      textAlign: "center",
                      gridRow: `${item?.grid_row}`,
                      gridColumn: `${item?.grid_column}`,
                    }}
                  >
                    {item?.type === "bar" ? (
                      <AdjustableChart apiData={item} item={item} setOpenDeleteChartModal={setOpenDeleteChartModal} setOpenEditChartModal={setOpenEditChartModal} currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                        defaultType="bar" />  
                      // <RCBarChart
                      //   apiData={item}
                      //   item={item}
                      //   setOpenDeleteChartModal={setOpenDeleteChartModal}
                      //   setOpenEditChartModal={setOpenDeleteChartModal}
                      //   currPage={currPage}
                      //   chartSlug={chartSlug}
                      //   setChartSlug={setChartSlug}
                      //   dashCharthartSlug={dashCharthartSlug}
                      //   setDashChartSlug={setDashChartSlug}
                      // />
                    ) : item?.type === "line" ? (
                      <AdjustableChart apiData={item} item={item} setOpenDeleteChartModal={setOpenDeleteChartModal} setOpenEditChartModal={setOpenEditChartModal} currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                        defaultType="line" />
                      // <LineChart
                      //   item={item}
                      //   data={item?.data}
                      //   setOpenDeleteChartModal={setOpenDeleteChartModal}
                      //   setOpenEditChartModal={setOpenEditChartModal}
                      //   currPage={currPage}
                      //   chartSlug={chartSlug}
                      //   setChartSlug={setChartSlug}
                      //   dashCharthartSlug={dashCharthartSlug}
                      //   setDashChartSlug={setDashChartSlug}
                      // />
                    ) : item?.type === "pie" ? (
                      <PieChart
                        item={item}
                        pieData={item?.data}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        setOpenEditChartModal={setOpenEditChartModal}
                        currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : item?.type === "number-1" ? (
                      <NumberCard
                        item={item}
                        data={item?.data}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        setOpenEditChartModal={setOpenEditChartModal}
                        currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : item?.type === "table" ? (
                      <TableChart
                        data={item?.data}
                        item={item}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        setOpenEditChartModal={setOpenEditChartModal}
                        currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : item?.type === "vector_map" ? (
                      <VectorMap
                        data={item?.data}
                        item={item}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        setOpenEditChartModal={setOpenEditChartModal}
                        currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : item?.type === "map" ? (
                      <Map
                        data={item?.data}
                        item={item}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        setOpenEditChartModal={setOpenEditChartModal}
                        currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : item?.type === "multiple_bar" ? (
                      <RCMultipleBarChart
                        apiData={item}
                        item={item}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        setOpenEditChartModal={setOpenEditChartModal}
                        currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : item?.type === "multiple_line" ? (
                      <RCMultiLineChart
                        apiData={item}
                        item={item}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        setOpenEditChartModal={setOpenEditChartModal}
                        currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : item?.type === "stacked_bar" ? (
                      <RCStackedBarChart
                        apiData={item}
                        item={item}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        setOpenEditChartModal={setOpenEditChartModal}
                        currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : item?.type === "percent_area" ? (
                      <RCPercentAreaChart
                        apiData={item}
                        item={item}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        setOpenEditChartModal={setOpenEditChartModal}
                        currPage={currPage}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : item?.type === "sub_title" ? (
                      <SubTitle
                        item={item}
                        currPage={currPage}
                        setOpenEditChartModal={setOpenEditChartModal}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : //put conditions for other types of charts
                      item?.type === "legend" ? (
                      <Legend
                        item={item}
                        currPage={currPage}
                        setOpenEditChartModal={setOpenEditChartModal}
                        setOpenDeleteChartModal={setOpenDeleteChartModal}
                        chartSlug={chartSlug}
                        setChartSlug={setChartSlug}
                        dashCharthartSlug={dashCharthartSlug}
                        setDashChartSlug={setDashChartSlug}
                      />
                    ) : 
                    null}
                  </div>
                );
              })}
            </>
          )}
        </div>

        {chartData?.length === 0 ||
          (chartData === null && (
            <div className="flex flex-col h-3/4 items-center justify-center">
              <div className="mb-8">
                {/* <img
                  src={require("./../../Asset/Images/no-records.png")}
                  alt="No records"
                /> */}
              </div>
              <p>
                <b>The Dashboard is empty</b>
              </p>
              <p className="text-sm">Add your first chart</p>
              <div className="mt-5">
                <button
                  className="sub-grp px-3 py-2"
                  onClick={handleShowCreateTemplateModal}
                >
                  Add Chart
                </button>
              </div>
            </div>
          ))}

        {createDashboardModalShow && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Add Chart</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleCreateTemplateSubmit}
              >
                <div className="mt-5 mb-5">
                  <div>
                    <label>
                      Select Chart<span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={
                          chartList && chartList.length > 0
                            ? formData.selectedChart || ""
                            : ""
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            selectedChart: e.target.value,
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        {chartList && chartList.length > 0 ? (
                          <>
                            <option value="" disabled>
                              Select a chart
                            </option>
                            {chartList.map((chart, index) => (
                              <option key={index} value={chart.chrt}>
                                {chart.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="" disabled>
                            There are no charts to display. You can add a new
                            chart by clicking on the New Chart Button.
                          </option>
                        )}
                      </select>

                      <button
                        type="button"
                        className="btn-popup py-2 rounded-lg text-sm text-medium w-32"
                        onClick={handleNewChart}
                      >
                        New Chart
                      </button>
                    </div>
                    {/* X axis and Y axis input fields */}
                    <div className="mb-5 mt-4">
                      <label>
                        Grid Column<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Grid Column"
                        value={formData.gridColumn}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            gridColumn: e.target.value,
                          })
                        }
                        className="custom-input py-2 px-2 mt-1 w-full"
                        required
                      />
                    </div>

                    <div className="mb-5">
                      <label>
                        Grid Row<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Grid Row"
                        value={formData.gridRow}
                        onChange={(e) =>
                          setFormData({ ...formData, gridRow: e.target.value })
                        }
                        className="custom-input py-2 px-2 mt-1 w-full"
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* bottom button */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseDas}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {createNewChartModalShow && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col w-1/2">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Add New Chart Type</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleCreateNewChartType}
              >
                <div className="mt-5 mb-5">
                  <div className="mb-5">
                    <label>
                      Name<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Name of the chart"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      className="custom-input py-2 px-2 mt-1 w-full"
                      required
                    />
                  </div>
                  <div className="mb-5">
                    <label>
                      Description<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Describe for the chart"
                      value={formData.description}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          description: e.target.value,
                        })
                      }
                      className="custom-input py-2 px-2 mt-1 w-full"
                      required
                    />
                  </div>
                  <div className="mt-4">
                    <label>
                      Select Dataset<span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={formData.selectedDataset || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            selectedDataset: e.target.value,
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        <option value="" disabled>
                          Select a dataset
                        </option>
                        {datasetType && datasetType.length > 0 ? (
                          datasetType.map((dataset, index) => (
                            <option key={index} value={dataset.datast}>
                              {dataset.dataset_name}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            There are no datasets to display.
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="mt-4">
                    <label>
                      Select Chart Type<span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={formData.selectedChartType || ""}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setFormData({
                            ...formData,
                            selectedChartType: selectedValue,
                          });

                          // Trigger the API call separately
                          if (selectedValue) {
                            fetchChartData(selectedValue);
                          }
                        }}
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        <option value="" disabled>
                          Select a chart type
                        </option>
                        {chartType && chartType.length > 0 ? (
                          chartType.map((chart, index) => (
                            <option key={index} value={chart}>
                              {chart}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            There are no chart types to display.
                          </option>
                        )}
                      </select>
                    </div>
                  </div>

                  {/* New section for displaying chart attributes */}
                  {chartAttributes.length > 0 && (
                    <div className="mt-5">
                      {chartAttributes
                        .sort((a, b) =>
                          a.name === "title" ? -1 : b.name === "title" ? 1 : 0
                        )
                        .map((attribute, index) => (
                          <div key={index} className="mb-5">
                            <label>{attribute.name}</label>
                            <input
                              type="text"
                              value={attribute.value || ""}
                              onChange={(e) => {
                                const updatedAttributes = [...chartAttributes];
                                updatedAttributes[index].value = e.target.value;
                                setChartAttributes(updatedAttributes);
                              }}
                              className="custom-input py-2 px-2 mt-1 w-full"
                            />
                          </div>
                        ))}
                    </div>
                  )}
                </div>

                {/* bottom button */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseNewChartModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {openEditChartModal && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Edit Chart</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleUpdateEditChart}
              >
                <div className="mt-5 mb-5">
                  <div>
                    <label>
                      Select Chart<span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={editChartDetails?.chrt || ""}
                        onChange={(e) =>
                          setEditChartDetails({
                            ...editChartDetails,
                            chrt: e.target.value,
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        {chartList && chartList.length > 0 ? (
                          <>
                            <option value="" disabled>
                              Select a chart
                            </option>
                            {chartList.map((chart, index) => (
                              <option key={index} value={chart.chrt}>
                                {chart.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="" disabled>
                            There are no charts to display. You can add a new
                            chart by clicking on the New Chart Button.
                          </option>
                        )}
                      </select>

                      <button
                        type="button"
                        className="btn-popup py-2 rounded-lg text-sm text-medium w-48"
                        onClick={handleEditChartAttributes}
                      >
                        Edit Chart Attributes
                      </button>
                    </div>

                    {/* Grid Column input */}
                    <div className="mb-5 mt-4">
                      <label>
                        Grid Column<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Grid Column"
                        value={editChartDetails?.gridColumn || ""}
                        onChange={(e) =>
                          setEditChartDetails({
                            ...editChartDetails,
                            gridColumn: e.target.value,
                          })
                        }
                        className="custom-input py-2 px-2 mt-1 w-full"
                        required
                      />
                    </div>

                    {/* Grid Row input */}
                    <div className="mb-5">
                      <label>
                        Grid Row<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Grid Row"
                        value={editChartDetails?.gridRow || ""}
                        onChange={(e) =>
                          setEditChartDetails({
                            ...editChartDetails,
                            gridRow: e.target.value,
                          })
                        }
                        className="custom-input py-2 px-2 mt-1 w-full"
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* bottom button */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={() => setOpenEditChartModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {openEditChartAttributesModal && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col w-1/2">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Edit Chart Type</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleUpdateChartAttributes}
              >
                <div className="mt-5 mb-5">
                  <div className="mb-5">
                    <label>
                      Name<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Name of the chart"
                      value={editChartAttributes?.name}
                      onChange={(e) =>
                        setEditChartAttributes({
                          ...editChartAttributes,
                          name: e.target.value,
                        })
                      }
                      className="custom-input py-2 px-2 mt-1 w-full"
                      required
                    />
                  </div>
                  <div className="mb-5">
                    <label>
                      Description<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Describe the chart"
                      value={editChartAttributes?.description}
                      onChange={(e) =>
                        setEditChartAttributes({
                          ...editChartAttributes,
                          description: e.target.value,
                        })
                      }
                      className="custom-input py-2 px-2 mt-1 w-full"
                      required
                    />
                  </div>
                  <div className="mt-4">
                    <label>
                      Select Dataset<span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={editChartAttributes?.datast || ""}
                        onChange={(e) =>
                          setEditChartAttributes({
                            ...editChartAttributes,
                            datast: e.target.value,
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        <option value="" disabled>
                          Select a dataset
                        </option>
                        {datasetType && datasetType.length > 0 ? (
                          datasetType.map((dataset, index) => (
                            <option key={index} value={dataset.datast}>
                              {dataset.dataset_name}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            There are no datasets to display.
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="mt-4">
                    <label>
                      Select Chart Type<span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={editChartAttributes?.chart_type || ""}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setEditChartAttributes({
                            ...editChartAttributes,
                            chart_type: selectedValue,
                          });

                          // Trigger the API call separately
                          if (selectedValue) {
                            fetchChartData(selectedValue);
                          }
                        }}
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        <option value="" disabled>
                          Select a chart type
                        </option>
                        {chartType && chartType.length > 0 ? (
                          chartType.map((chart, index) => (
                            <option key={index} value={chart}>
                              {chart}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            There are no chart types to display.
                          </option>
                        )}
                      </select>
                    </div>
                  </div>

                  {chartAttributes && chartAttributes.length > 0 && (
                    <div className="mt-5">
                      {chartAttributes
                        .sort((a, b) =>
                          a.name === "title" ? -1 : b.name === "title" ? 1 : 0
                        )
                        .map((attribute, index) => (
                          <div key={index} className="mb-5">
                            <label>{attribute.name}</label>
                            <input
                              type="text"
                              value={attribute.value || ""}
                              onChange={(e) => {
                                const updatedAttributes = [...chartAttributes];
                                updatedAttributes[index].value = e.target.value;
                                setChartAttributes(updatedAttributes);
                              }}
                              className="custom-input py-2 px-2 mt-1 w-full"
                            />
                          </div>
                        ))}
                    </div>
                  )}
                </div>

                {/* bottom button */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseEditChartAttributesModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {openDeleteChartModal && (
          <>
            <div className="overlay">
              <form
                style={{ width: "35vw" }}
                className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2"
                onSubmit={handleDeleteChart}
              >
                <div className=" flex-grow overflow-y-auto ">
                  <div className="flex justify-between items-center gap-5">
                    <div style={{ fontSize: 14 }}>
                      <b>Delete Chart</b>
                    </div>
                  </div>
                  <div className="mt-4 text-xs mb-4">
                    <p>Are you sure you want to delete this chart?</p>
                  </div>
                </div>
                {/* bottom buttom */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs"
                    onClick={handleCloseDeleteChartModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="delete-obj px-4 py-2 rounded-lg mt-2 text-xs"
                  >
                    Delete Chart
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ManageLayoutTable;
