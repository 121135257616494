import React from 'react';

const SubTitle = ({item, setOpenDeleteChartModal, setOpenEditChartModal, currPage, setChartSlug, setDashChartSlug}) => {

    const handleShowMoreOptions = (event) => {
        let el = document.querySelectorAll(".more-options-wrapper .more-options");
    
        var x = event.target
          .closest(".more-options-wrapper")
          .querySelector(".more-options");
        if (x.style.display === "none") {
          for (let i = 0; i < el.length; i++) {
            el[i].style.display = "none";
          }
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      };

    return (
        <>       
           <>{item?.title !== "" ? (
            <div className="chart-heading" style={{padding: "12px"}}>{item?.title} </div>
          ) : (
            <div className="mt-4"></div>
          )}
           {currPage === 'manage_layout' && <div className="text-right more-options-wrapper" style={{fontSize: "15px", marginTop: "-30px"}}>
          <button
            type="button"
            onClick={handleShowMoreOptions}
            title="More"
            className="btn-more-options"
          >
            <i className="fa-solid fa-ellipsis-vertical not-0"></i>
          </button>
          <div className="more-options" style={{ display: "none" }}>
            <button type="button" onClick={() => {setOpenEditChartModal(true); setChartSlug(item.cht); setDashChartSlug(item.dash_chrt); console.log(item, 'formData');}}>
            <i className="fa-solid fa-pencil" aria-hidden="true"></i> <span>Edit Chart</span>
            </button>
            <button
              type="button"
              onClick={() => {setOpenDeleteChartModal(true); setChartSlug(item.cht); setDashChartSlug(item.dash_chrt);}}
            >
              <i className="fas fa-trash-alt"></i> {" "}
              <span>Delete Chart</span>
            </button>
          </div>
        </div> }</>
        </>
    )
}

export default SubTitle;