import React, { useEffect, useState, useRef } from "react";
import ReactFlow, { Handle, Position, useReactFlow } from "reactflow";
import "reactflow/dist/style.css";

const CustomNode = ({ data, id }) => {
  const specialNodeIds = ["0", "5"];
  const categoryNodeIds = [
    'nii',
    'noi',
    "aea_aa",
    "nim_te",
    "noi_aa",
    "noe_aa",
    "deposit_service_charges",
    "fiduciary_activities",
    "trading_revenue",
    "securities_bkr",
    "gains",
    "others",
    "personnel",
    "occupancy",
    "other_noe",
    "int_loans",
    "int_investment",
    "int_other",
    "deposits",
    "cost_ffp",
    "cost_other",
    "cost_debt",
    "yield_ib",
    "yield_ffs",
    "yield_foreign",
    "yield_ag",
    "yield_credit",
    "yield_0",
    "yield_ci",
    "yield_re",
    "yield_sec",
    "yield_mbs",
    "yield_ust",
    "transact_accts",
    "cost_of_other_svgs",
    "cost_of_time_deps",
    "cost_of_fod",
    "cost_of_other_borrow",
    "other_interest_expense",
  ];

  const isSpecialNode = specialNodeIds.includes(id);
  const isCategoryNode = categoryNodeIds.includes(id);

  const nodeStyle = {
    padding: isSpecialNode ? "5px" : "5px",
    borderRadius: "8px",
    boxShadow: isSpecialNode ? "none" : "0 4px 6px rgba(0, 0, 0, 0.1)",
    backgroundColor: isSpecialNode ? "transparent" : data.color,
    color: data.textColor || "black",
    minWidth: isSpecialNode ? "80px" : "120px",
    textAlign: "center",
    width: '140px',
    height: '60px'
  };

  const labelStyle = {
    fontSize: isSpecialNode ? "10px" : "10px",
    whiteSpace: "pre-line",
    textAlign: "center",
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  const valueStyle = {
    fontWeight: "bold",
    fontSize: isSpecialNode ? "20px" : "14px",
    overflow: 'hidden'
  };

  const categoryContainerStyle = {
    backgroundColor: "#b7b7b7",
    padding: "5px",
  };

  return (
    <div style={isCategoryNode ? categoryContainerStyle : {}}>
      <div style={nodeStyle}>
        <Handle
          type="target"
          position={Position.Left}
          style={{ border: "none", background: "transparent" }}
        />
        <div style={labelStyle}>{data.label}</div>
        <div style={valueStyle}>{data.value}</div>
        <Handle
          type="source"
          position={Position.Right}
          style={{ border: "none", background: "transparent" }}
        />
      </div>
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const NewMindmap = ({
  apiData,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState("");
  const [data, setData] = useState([]);
  const reactFlowWrapper = useRef(null);
  const { fitView } = useReactFlow();

  console.log(item, "<<< ITEM >>>");

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      setData(apiData?.data);
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [isLoading, apiData]);

  useEffect(() => {
    if (item) {
      try {
        const { data, nodes: nodesStr, edges: edgesStr } = item;
  
        // Log raw JSON strings for debugging
        console.log("Nodes JSON:", nodesStr);
        console.log("Edges JSON:", edgesStr);
  
        // Fix the formatting of nodes and edges JSON strings
        const formatJsonString = (jsonStr) => {
          try {
            // Replace single quotes with double quotes and remove trailing commas
            return jsonStr
              .replace(/'/g, '"') // Replace single quotes with double quotes
              .replace(/,\s*([}\]])/g, "$1"); // Remove trailing commas before closing braces and brackets
          } catch (error) {
            console.error("Error formatting JSON string:", error);
            return jsonStr; // Return the original string if formatting fails
          }
        };
  
        const formattedNodesStr = formatJsonStringQuote(formatJsonString(nodesStr));
        const formattedEdgesStr = formatJsonStringQuote(formatJsonString(edgesStr));
  
        // Parse the corrected JSON strings
        const parsedNodes = JSON.parse(formattedNodesStr);
        const parsedEdges = JSON.parse(formattedEdgesStr);
  
        // Clear values of all nodes before setting new ones
        const clearedNodes = parsedNodes.map((node) => ({
          ...node,
          data: { ...node.data, value: null }, // Set value to null or empty to clear previous data
        }));
  
        // Map values from the data array to the corresponding nodes
        const values = data[0]; // Assuming data is an array with one object
  
        // Update nodes with new values
        const updatedNodes = clearedNodes.map((node) => {
          // Match node id with the keys in values
          const value = values[node.id];
          if (value !== undefined && value !== null) {
            node.data.value = value;
          }
          return node;
        });
  
        console.log("Nodes :", updatedNodes);
        console.log("Edges :", parsedEdges);
        setNodes(updatedNodes);
        setEdges(parsedEdges);
      } catch (error) {
        console.error("Error processing mind map data:", error);
      }
    }
  }, [item]);
  

  function formatJsonStringQuote(jsonString) {
    jsonString = jsonString.replace(/'/g, '"');
    jsonString = jsonString.replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3');

    return jsonString;
  }

  useEffect(() => {
    if (reactFlowWrapper.current) {
      fitView({ padding: 0.2 });
    }
  }, [fitView, nodes, edges, apiData]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div className="mt-4"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div
          style={{ width: "100%", height: "50vh", overflow: "hidden" }}
          ref={reactFlowWrapper}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            fitView
            zoomOnScroll={false}
            panOnScroll={false}
            panOnDrag={false}
            proOptions={{ hideAttribution: true }}
          />
        </div>
      )}{" "}
    </>
  );
};

export default NewMindmap;
