import React, { useEffect, useState } from "react";
import axios from "axios";
import UserService from "../services/UserService.js";
import { useNavigate } from "react-router-dom";

const serverConfig = require("../config/server.js");

const InviteUser = () => {

    const searchParams = new URLSearchParams(window.location.search);
    const ref_id = searchParams.get('ref_id');
    const [inviteeName, setInviteeName] = useState('');
    const [orgName, setOrgName] = useState('');
    const [isExists, setIsExists] = useState();
    const [tokenStatus, setTokenStatus] = useState('');
    const [declineInvite, setDeclineInvite] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const inviteUpdate = (status, e) => {
        e.preventDefault();
        setLoading(true);
        axios.post(serverConfig.api_base_url + "invite_update", {
            ref_token: ref_id,
            invite_status: status
        }).then(response=> {
            console.log(response.data)
            if(status === 'accepted' && response.data.status === 200)
            {
                UserService.doLogin();
            }
            else if(status === 'declined' && response.data.status === 200)
            {
                setTokenStatus('Declined');
                setDeclineInvite(false);
                setLoading(false);
            }
        })
    }

    const checkValidity = () => {
        axios.post(serverConfig.api_base_url + "invite_validate", 
        {
           ref_token: ref_id
        }
        ).then(response=> {
          console.log(response.data);
          if(response.data.status === 502) {
            setTokenStatus('Invalid');
          }
          else if(response.data.status === 200) {
            //setTokenStatus('Valid');
            console.log(response.data.items.invitee_name)
            setInviteeName(response.data.items.invitee_name);
            setOrgName(response.data.items.organization_name);
            setIsExists(response.data.items.is_exists);
          }
        }).catch(err=> {
            console.log(err);
        })
    }

    useEffect(() => {

        if(tokenStatus === '')
        {
        checkValidity();
        }

    }, []);

    const LogIn = (e) => {
        if(isExists === 1) {
            // some procedure to add that user to that org...
            inviteUpdate('accepted', e);
        }
        else {
            navigate(`/signup?ref_id=${ref_id}`);
        }
    }

    return(
        <>
        <div className={'content-body-collapse'} >
        <div className="container-fluid metadata mt-64">
            {tokenStatus === 'Invalid' ?  <div className='flex flex-col h-3/4 items-center justify-center'>
            <div className='mb-8'><img src={require('./../Asset/Images/no-records.png')} alt="No records" /></div>
            <p className="error-display">This link is expired.</p>
            </div> :
            tokenStatus === 'Declined' ?  <div className='flex flex-col h-3/4 items-center justify-center'>
            <div className='mb-8'><img src={require('./../Asset/Images/no-records.png')} alt="No records" /></div>
            <p className="error-display">You have declined the invitation to join this organization. The organization will be notified of your decision.</p>
            </div> :
         <div className='flex flex-col h-3/4 items-center justify-center'>
        <div className='mb-8'><img src={require('./../Asset/Images/no-records.png')} alt="No records" /></div>
        <p><b> You have been invited to join {orgName}, by {inviteeName}</b></p>
        <p className='text-sm'> Please click below to accept the invitation.</p>
        <div className='flex flex-row mt-5'>
            <button className='sub-grp px-3 py-2 mr-4' onClick={LogIn}>Accept</button>
            <button className='cancel-btn px-3 py-2' onClick={()=>{setDeclineInvite(true);}}>Decline</button>
        </div>
        </div> } 
        </div>
        {declineInvite && <div className='overlay'>
          <form style={{width : "35vw"}} className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2' onSubmit={(e)=>{inviteUpdate('declined', e);}}>
            <div className=' flex-grow overflow-y-auto '>
              <div className='flex justify-between items-center gap-5'>
                <div style={{fontSize:14}}>Decline Invite</div>
              </div>
      
              <div className='mt-4 text-xs mb-4'>
                <p>Are you sure you want to decline this invitation?</p>
              </div>
            </div>
            {/* bottom buttom */}
            <div className='spacing-div flex flex-row'>
              <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs' onClick={() => {setDeclineInvite(false);}}>Cancel</button>
              <button type="submit" className='delete-obj px-4 py-2 rounded-lg mt-2 text-xs' disabled={loading} style={loading ? {display: "flex", fontSize: "13px"} : {}}>Decline {loading && <div className="signup-loader" style={{ marginLeft: '15px' }}></div>}</button>
            </div>
          </form>
        </div>}
        </div>
        </>
    )
}

export default InviteUser;