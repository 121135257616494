import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const MultipleBarChart = ({
  data,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const svgRef = useRef();
  const wrapperRef = useRef();

  const convertData = (item) => {
    const data = item?.data;
    const x_cord = item?.x_cord;
    const y_cord = item?.y_cord;
    const group = item?.group;

    const groupedData = data.reduce((acc, entry) => {
      const groupValue = entry[group];
      const xValue = entry[x_cord];
      const yValue = parseFloat(entry[y_cord]);

      if (!acc[groupValue]) {
        acc[groupValue] = { [group]: groupValue, values: [], total: 0 };
      }

      acc[groupValue].values.push({ [x_cord]: xValue, [y_cord]: yValue });
      acc[groupValue].total += yValue;

      return acc;
    }, {});

    Object.values(groupedData).forEach((group) => {
      group.values.forEach((value) => {
        value[y_cord] = ((value[y_cord] / group.total) * 100).toFixed(2);
      });
    });

    return Object.values(groupedData);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = wrapperRef.current.clientWidth;
      const height = Math.max(
        250,
        Math.min(wrapperRef.current.clientHeight / 2, 400)
      ); // Min 250px, Max 400px
      renderChart(width, height);
    };

    const renderChart = (width, height) => {
      setIsLoading(false);
      const svg = d3.select(svgRef.current);
      svg.selectAll("*").remove(); // Clear previous elements

      const margin = { top: 20, right: 40, bottom: 120, left: 40 };

      const formattedData = convertData(item);

      svg.attr("width", width).attr("height", height);

      const numGroups = formattedData.length;
      const barWidth =
        Math.floor((width - margin.left - margin.right) / numGroups) - 10;

      const x0 = d3
        .scaleBand()
        .domain(formattedData.map((d) => d[item?.group]))
        .rangeRound([margin.left, width - margin.right])
        .paddingInner(0.1);

      const x1 = d3
        .scaleBand()
        .domain(formattedData[0].values.map((d) => d[item?.x_cord]))
        .rangeRound([0, x0.bandwidth()]);

      const y = d3
        .scaleLinear()
        .domain([0, 100])
        .nice()
        .rangeRound([height - margin.bottom, margin.top]);

      const color = d3
        .scaleOrdinal()
        .domain(formattedData[0].values.map((d) => d[item?.x_cord]))
        .range(d3.schemeCategory10);

      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip-dashboards");

      const yAxisGrid = svg
        .append("g")
        .attr("class", "y-axis-grid")
        .attr("transform", `translate(${margin.left},0)`)
        .call(
          d3
            .axisLeft(y)
            .tickSize(-width + margin.left + margin.right)
            .tickFormat("")
            .ticks(5)
        );

      yAxisGrid.select(".domain").remove();

      svg
        .append("g")
        .selectAll("g")
        .data(formattedData)
        .enter()
        .append("g")
        .attr("transform", (d) => `translate(${x0(d[item?.group])},0)`)
        .selectAll("rect")
        .data((d) => d.values)
        .enter()
        .append("rect")
        .attr("x", (d) => x1(d[item?.x_cord]))
        .attr("y", (d) => y(d[item?.y_cord]))
        .attr("width", x1.bandwidth())
        .attr("height", (d) => y(0) - y(d[item?.y_cord]))
        .attr("fill", (d, i) => {
          const col = color(d[item?.x_cord]);
          d.originalColor = col;
          return col;
        })
        .on("mouseover", function (event, d) {
          const currentColor = d3.select(this).attr("fill");
          const lightenedColor = d3
            .color(currentColor)
            .brighter(-0.9)
            .toString();

          d3.select(this)
            .transition()
            .duration(200)
            .attr("fill", lightenedColor);

          tooltip.transition().duration(200).style("opacity", 0.9);

          const parentData = d3.select(this.parentNode).datum();
          tooltip
            .html(
              `${item.group}<br>${parentData[item.group]}<br><br>${
                d[item.x_cord]
              }: <br>${d[item.y_cord]}`
            )
            .style("left", event.pageX + 5 + "px")
            .style("top", event.pageY - 28 + "px");
        })
        .on("mouseout", function (event, d) {
          d3.select(this)
            .transition()
            .duration(200)
            .attr("fill", d.originalColor);

          tooltip.transition().duration(500).style("opacity", 0);
        });

      svg
        .append("g")
        .call(
          d3
            .axisLeft(y)
            .ticks(5)
            .tickFormat((d) => `${d}%`)
        )
        .attr("transform", `translate(${margin.left},0)`)
        .select(".domain")
        .remove();

      svg
        .append("g")
        .call(d3.axisBottom(x0))
        .attr("transform", `translate(0,${height - margin.bottom})`);

      const legendHeight = 60; // Adjust based on desired height

      const legendContainer = svg
        .append("foreignObject")
        .attr("width", width - margin.left - margin.right)
        .attr("height", legendHeight)
        .attr("x", margin.left)
        .attr("y", height - margin.bottom + 40)
        .append("xhtml:div")
        .attr("class", "legend-container")
        .style("width", `${width - margin.left - margin.right}px`)
        .style("height", `${legendHeight}px`)
        .style("overflow-y", "auto")
        .style("display", "flex")
        .style("flex-wrap", "wrap");

      const legendData = formattedData[0].values.map((d) => d[item?.x_cord]);

      legendData.forEach((d) => {
        const legendItem = legendContainer
          .append("div")
          .style("display", "flex")
          .style("align-items", "center")
          .style("margin-right", "20px")
          .style("margin-bottom", "10px");

        legendItem
          .append("svg")
          .attr("width", 10)
          .attr("height", 10)
          .append("circle")
          .attr("cx", 5)
          .attr("cy", 5)
          .attr("r", 5)
          .attr("fill", color(d));

        legendItem.append("div").style("margin-left", "9px").text(d);
      });

      return () => {
        svg.selectAll("*").remove();
        tooltip.remove();
      };
    };

    if (item?.data.length > 0 && item?.data !== "fetching") {
      const width = wrapperRef.current.clientWidth;
      const height = Math.max(
        250,
        Math.min(wrapperRef.current.clientHeight / 2, 400)
      ); // Min 250px, Max 400px
      renderChart(width, height);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        d3.select("body").selectAll(".tooltip").remove();
      };
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [data, item]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div className="mt-4"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div
          ref={wrapperRef}
          style={{
            overflow: "visible",
            margin: "15px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <svg ref={svgRef}></svg>
        </div>
      )}
    </>
  );
};

export default MultipleBarChart;
