import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

const MultilineChart = ({
  apiData,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const svgRef = useRef();
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const transformData = (data, group, xCord, yCord) => {
    const groupedData = data?.reduce((acc, curr) => {
      if (!acc[curr[xCord]]) {
        acc[curr[xCord]] = { [xCord]: curr[xCord] };
      }
      acc[curr[xCord]][curr[group]] = curr[yCord];
      return acc;
    }, {});

    return Object.values(groupedData);
  };

  const sanitizeKeyName = (key) => key.replace(/[^a-zA-Z0-9]/g, "_");

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const data = transformData(
        apiData?.data,
        apiData?.group,
        apiData?.x_cord,
        apiData?.y_cord
      );

      const yKeys = [...new Set(apiData?.data.map((d) => d[apiData?.group]))];
      const xKey = apiData?.x_cord;
      console.log(data, yKeys, "data_line");

      const svg = d3.select(svgRef.current);
      const { width, height } = svg.node().getBoundingClientRect();
      const margin = { top: 20, right: 80, bottom: 150, left: 80 };

      svg.selectAll("*").remove();

      const g = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const x = d3
        .scalePoint()
        .domain(data.map((d) => d[xKey]))
        .range([0, width - margin.left - margin.right])
        .padding(0.5);

      const maxYValue = d3.max(data.flatMap((d) => yKeys.map((key) => d[key])));
      const tickInterval = Math.ceil(maxYValue / 5);
      const y = d3
        .scaleLinear()
        .domain([0, maxYValue])
        .range([height - margin.top - margin.bottom, 0]);

      const yTicks = d3.range(0, maxYValue + tickInterval, tickInterval);
      const color = d3.scaleOrdinal(d3.schemeCategory10);

      const line = d3
        .line()
        .x((d) => x(d[xKey]))
        .y((d) => y(d.value));

      // Append x axis grid lines
      g.append("g")
        .attr("class", "axis axis--x")
        .attr(
          "transform",
          `translate(0,${height - margin.top - margin.bottom})`
        )
        .call(
          d3
            .axisBottom(x)
            .tickSize(-height + margin.top + margin.bottom)
            .tickFormat("")
        );

      // Append y axis grid lines
      g.append("g")
        .attr("class", "axis axis--y")
        .call(
          d3
            .axisLeft(y)
            .tickSize(-width + margin.left + margin.right)
            .tickFormat("")
        );

      // Append x axis
      g.append("g")
        .attr("class", "axis axis--x")
        .attr(
          "transform",
          `translate(0,${height - margin.top - margin.bottom})`
        )
        .call(d3.axisBottom(x).tickFormat((d) => d))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-0.8em")
        .attr("dy", "0.15em")
        .attr("transform", "rotate(-45)");

      // Append y axis with custom ticks
      g.append("g")
        .attr("class", "axis axis--y")
        .call(d3.axisLeft(y).tickValues(yTicks))
        .append("text")
        .attr("fill", "#000")
        .attr("transform", "rotate(-90)")
        .attr("x", -height / 2 + 70)
        .attr("y", -margin.left + 5)
        .attr("dy", "1em")
        .attr("class", "y-axis-label")
        .attr("text-anchor", "middle")
        .attr("font-weight", "400")
        .text(item?.y_axis_label);

      // Append x axis label
      g.append("text")
        .attr("fill", "#000")
        .attr("class", "x-axis-label")
        .attr("x", (width - margin.left - margin.right) / 2)
        .attr("y", height - margin.bottom + 50)
        .attr("text-anchor", "middle")
        .attr("font-weight", "400")
        .text(item?.x_axis_label);

      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip-dashboards")
        .style("opacity", 0);

      yKeys.forEach((key) => {
        const sanitizedKey = sanitizeKeyName(key);
        const lineData = data.map((d) => ({ [xKey]: d[xKey], value: d[key] }));

        g.append("path")
          .datum(lineData)
          .attr("class", "line")
          .attr("d", line)
          .attr("stroke", color(key))
          .attr("fill", "none")
          .attr("stroke-width", 2);

        g.selectAll(`.dot-${sanitizedKey}`)
          .data(lineData)
          .enter()
          .append("circle")
          .attr("class", `dot-${sanitizedKey}`)
          .attr("cx", (d) => x(d[xKey]))
          .attr("cy", (d) => y(d.value))
          .attr("r", 5)
          .attr("fill", color(key))
          .on("mouseover", function (event, d) {
            tooltip.transition().duration(200).style("opacity", 0.9);
            tooltip
              .html(`${key}<br/>${xKey}: ${d[xKey]}<br/>Value: ${d.value}`)
              .style("left", event.pageX + 5 + "px")
              .style("top", event.pageY - 28 + "px");
          })
          .on("mouseout", function () {
            tooltip.transition().duration(500).style("opacity", 0);
          });
      });

      const legendHeight = 60; // Adjust based on desired height

      const legendContainer = svg
        .append("foreignObject")
        .attr("width", width - margin.left - margin.right)
        .attr("height", legendHeight)
        .attr("x", margin.left)
        .attr("y", height - margin.bottom + 90)
        .append("xhtml:div")
        .attr("class", "legend-container")
        .style("width", `${width - margin.left - margin.right}px`)
        .style("height", `${legendHeight}px`)
        .style("overflow-y", "auto")
        .style("display", "flex")
        .style("flex-wrap", "wrap")
        .style("font-size", "10px");

      yKeys.forEach((key) => {
        const legendItem = legendContainer
          .append("div")
          .style("display", "flex")
          .style("align-items", "center")
          .style("margin-right", "20px")
          .style("margin-bottom", "10px");

        legendItem
          .append("svg")
          .attr("width", 10)
          .attr("height", 10)
          .append("circle")
          .attr("cx", 5)
          .attr("cy", 5)
          .attr("r", 5)
          .attr("fill", color(key));

        legendItem.append("div").style("margin-left", "5px").text(key);
      });
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [isLoading, apiData]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div className="mt-4"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div
          style={{
            overflow: "visible",
            display: "flex",
            justifyContent: "center",
            marginLeft: "40px",
            marginRight: "40px",
          }}
        >
          <svg ref={svgRef} width="1400" height="450"></svg>
        </div>
      )}
    </>
  );
};

export default MultilineChart;
