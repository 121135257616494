import React, { PureComponent, useRef, useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const RCPercentAreaChart = ({
  apiData,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const toPercent = (decimal, fixed = 0) =>
    `${(decimal * 100).toFixed(fixed)}%`;

  const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;

    return toPercent(ratio, 2);
  };

  const renderTooltipContent = (o) => {
    const { payload, label } = o;
    const total = payload.reduce((result, entry) => result + entry.value, 0);

    return (
      <div
        className="customized-tooltip-content"
        style={{
          maxWidth: "200px",
          wordWrap: "break-word",
          whiteSpace: "normal",
        }}
      >
        <p
          className="total"
          style={{ fontWeight: "500", fontSize: "14px" }}
        >{`${label} (Total: ${total})`}</p>
        <ul className="list" style={{ padding: "0", margin: "0" }}>
          {payload.map((entry, index) => (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,
                fontSize: "12px",
                listStyleType: "none",
                padding: "5px 0",
              }}
            >
              {`${entry.name}: ${entry.value} (${getPercent(
                entry.value,
                total
              )})`}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const generateColors = (count) => {
    const colors = [
      "#8884d8",
      "#82ca9d",
      "#ffc658",
      "#ff7300",
      "#d7aefb",
      "#d4e157",
      "#ff5722",
      "#795548",
      "#607d8b",
      "#ffeb3b",
    ];
    return colors.slice(0, count);
  };
  const svgRef = useRef();
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [yKeys, setYKeys] = useState([]);

  const transformData = (data, groupKey, xCordKey, yCordKey) => {
    return data.reduce((acc, current) => {
      const locationId = current[yCordKey]; // Access the yCord value dynamically
      const group = current[groupKey]; // Access the group value dynamically
      const value = current[xCordKey]; // Access the xCord value dynamically

      const existingLocation = acc.find(
        (item) => item.location_id === locationId
      );

      if (existingLocation) {
        existingLocation[group] = value;
      } else {
        acc.push({
          location_id: locationId, // Use "location_id" instead of "location"
          [group]: value,
        });
      }

      return acc;
    }, []);
  };

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const chartdata = transformData(
        apiData?.data,
        apiData?.group,
        apiData?.x_cord,
        apiData?.y_cord
      );
      setData(chartdata);
      const keys = [...new Set(apiData?.data.map((d) => d[apiData?.group]))];
      setYKeys(keys);
      console.log(chartdata, keys);
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [isLoading, apiData]);

  const colors = generateColors(yKeys.length);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      <>
        <div className="chart-title">{noDataFound}</div>
        {currPage === "manage_layout" && (
          <div
            className="text-right more-options-wrapper"
            style={{
              fontSize: "15px",
              marginTop: "-30px",
              marginBottom: "25px",
            }}
          >
            <button
              type="button"
              onClick={handleShowMoreOptions}
              title="More"
              className="btn-more-options"
            >
              <i className="fa-solid fa-ellipsis-vertical not-0"></i>
            </button>
            <div className="more-options" style={{ display: "none" }}>
              <button
                type="button"
                onClick={() => {
                  setOpenEditChartModal(true);
                  setChartSlug(item.cht);
                  setDashChartSlug(item.dash_chrt);
                  console.log(item, "formData");
                }}
              >
                <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                <span>Edit Chart</span>
              </button>
              <button
                type="button"
                onClick={() => {
                  setOpenDeleteChartModal(true);
                  setChartSlug(item.cht);
                  setDashChartSlug(item.dash_chrt);
                }}
              >
                <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
              </button>
            </div>
          </div>
        )}
      </>{" "}
      :
      <>
        {item?.title !== "" ? (
          <div className="chart-title">{item?.title} </div>
        ) : (
          <div className="mt-4"></div>
        )}
        {currPage === "manage_layout" && (
          <div
            className="text-right more-options-wrapper"
            style={{
              fontSize: "15px",
              marginTop: "-30px",
              marginBottom: "25px",
            }}
          >
            <button
              type="button"
              onClick={handleShowMoreOptions}
              title="More"
              className="btn-more-options"
            >
              <i className="fa-solid fa-ellipsis-vertical not-0"></i>
            </button>
            <div className="more-options" style={{ display: "none" }}>
              <button
                type="button"
                onClick={() => {
                  setOpenEditChartModal(true);
                  setChartSlug(item.cht);
                  setDashChartSlug(item.dash_chrt);
                  console.log(item, "formData");
                }}
              >
                <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                <span>Edit Chart</span>
              </button>
              <button
                type="button"
                onClick={() => {
                  setOpenDeleteChartModal(true);
                  setChartSlug(item.cht);
                  setDashChartSlug(item.dash_chrt);
                }}
              >
                <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
              </button>
            </div>
          </div>
        )}
      </>
      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div style={{ height: "500px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={data}
              stackOffset="expand"
              margin={{
                top: 10,
                right: 30,
                left: 30,
                bottom: 80,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={item?.y_cord}
                interval={0}
                tickFormatter={(label) =>
                  label.length > 10 ? `${label.slice(0, 20)}...` : label
                }
                textAnchor="end"
                angle={-40}
              />
              <YAxis
                tickFormatter={toPercent}
                label={{
                  value: item?.y_axis_label,
                  angle: -90,
                  position: "insideLeft",
                  offset: 5,
                  dy: 50,
                  fontWeight: 600,
                }}
              />
              <Tooltip
                //content={renderTooltipContent}
                contentStyle={{
                  fontSize: "14px",
                  padding: "10px",
                  backgroundColor: "#f5f5f5",
                  opacity: "0.75",
                  borderRadius: "5px",
                }}
              />
              <Legend
                layout="horizontal"
                align="center"
                verticalAlign="top"
                wrapperStyle={{ fontSize: "12px", padding: "8px" }}
              />
              {yKeys?.map((key, index) => {
                return (
                  // <Line connectNulls={true} type="monotone" dataKey={key} stroke={colors[index]} activeDot={{ r: 8 }} isAnimationActive={true} animationEasing='ease-out' animationDuration={2000} />
                  <Area
                    type="monotone"
                    dataKey={key}
                    stackId="1"
                    stroke={colors[index]}
                    fill={colors[index]}
                  />
                );
              })}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default RCPercentAreaChart;
