import React from 'react'
import { useEffect,useState, useContext, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Context } from '../../../contextApi/context.js';
import axios from 'axios';
import UserService from '../../../services/UserService.js';
import Notification from '../../../common/notification/Notification.js';
import { loadStripe } from "@stripe/stripe-js";

const serverConfig = require("./../../../config/server.js");

function TableSubscription() {
    
      const [selectedRows, setSelectedRows] = useState([]);
      const {tab , setTab}=useContext(Context);
      const [appDataGridRender, setAppDataGridRender] = useState();
      const [tableData, setTableData] = useState();
      const [isOpen, setIsOpen] = useState(false);
      const [selectedOption, setSelectedOption] = useState(null);
      const {selectedOrganization} = useContext(Context);
      const [displayNone, setDisplayNone] = useState('none');
      const [loading, setLoading] = useState(false);

      /* token - start */
  const [token, setToken] = useState(null);
  const [subSlug, setSubSlug] = useState("");
  const [organizationList, setOrganizationList] = useState([]);
  const [userPrivileged, setUserPrivileged] = useState(false);
  const [rowHovered, setRowHovered] = useState('');
  const [payRowClicked, setPayRowClicked] = useState('');
  //const [modalClosed, setModalClosed] = useState(false);
  //const [actions, setActions] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipStates, setTooltipStates] = useState([]);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [memberProfile, setMemberProfile] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [userInvitePopup, setUserInvitePopup] = useState(false);
  const [userData, setUserData] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [paymentOptionsLookup, setPaymentOptionsLookup] = useState([]);
  const [paymentSessionId, setPaymentSessionId] = useState("");
  const [pubKey, setPubKey] = useState("");
  const [value, setValue] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [currentPaymentSessionTime, setCurrentPaymentSessionTime] = useState('');
  const [currentCancelPaymentSessionTime, setCurrentCancelPaymentSessionTime] = useState('');
  const [isPaid, setIsPaid] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const paymentId = searchParams.get('payment_session_id');
  const cancelPaymentId = searchParams.get('cancel_payment_session_id');

  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  }
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  }

  const dropdownRef = useRef(null);

  const toggleTooltip = (index) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipIndex(index);
    setTooltipStates(newTooltipStates);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, url:window.location.host})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        handleShowAlertModal(response.data.message, true);
        setTimeout(() => {
          handleCloseAlertModal();
        }, 6000);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(()=>{
    console.log(paymentId, 'session_id')
    setCurrentPaymentSessionTime(paymentId);
    setCurrentCancelPaymentSessionTime(cancelPaymentId);
  }, []);

  useEffect(()=>{

    if(currentPaymentSessionTime)
    {
      axios.post(serverConfig.app_base_url + "stripe/check_payment_status", {
        payment_session_id : currentPaymentSessionTime
      }).then(response=> {
        console.log(response, 'check_payment_status');
        if(response.data.status === 200) {
          handleShowAlertModal("Payment Successful");
          setIsPaid(!isPaid);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      }).catch(err=> {
        console.log(err);
      })
    }

  }, [currentPaymentSessionTime]);

  useEffect(()=>{

    if(currentCancelPaymentSessionTime)
    {
      axios.post(serverConfig.api_base_url + "payment_stripe_session_cancel", {
        token: token,
        org: selectedOrganization?.slug,
        cancel_payment_session_id : currentCancelPaymentSessionTime
      }).then(response=> {
        console.log(response, 'payment_stripe_session_cancel');
        if(response.data.status === 200) {
          setIsPaid(!isPaid);
          handleShowAlertModal("Payment Cancelled", true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      }).catch(err=> {
        console.log(err);
      })
    }

  }, [currentCancelPaymentSessionTime]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      console.log(event.target.classList, 'classlist')
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('more-options') && !event.target.classList.contains('more-options-wrapper') && !event.target.classList.contains('btn-more-options') && !event.target.classList.contains('fa-ellipsis-vertical') && !event.target.classList.contains('fa-solid')) {
        setShowDropdown('');
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
  
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  let times = 0;
  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    
      if(token !== null && token !== undefined && selectedOrganization?.slug !== undefined && selectedOrganization?.slug !== null){
        console.log('selectedOrganization', selectedOrganization)
        console.log('cToken', token);
        axios.post(serverConfig.api_base_url + "payment_subscription_plan_list_get",{token:token, org:selectedOrganization?.slug})
        .then(response=>{
          console.log("payment_subscription_plan_list_get response:", response);
          if(response.data.status === 200){
            let items = response.data.items;
            if (items !== null || items !== undefined){
              let appData = [];
              items.reverse().map((item, index) => {
                let curRole = [];
                curRole = item.roles;
              });
              console.log(items, 'payment_subscription_plan_list_get');
              console.log(appData);
              setTableData(items);
              setAppDataGridRender(appData);
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    
  }, [token, selectedOrganization, isPaid]);

  useEffect(()=>{
    if(token !== null && token !== undefined && subSlug) {
      axios.post(serverConfig.api_base_url + "payment_gateway_list_get", {
          token: token,
          org: selectedOrganization?.slug,
          pln_avail: subSlug
      }).then(response => {
          console.log(response.data);
          const options = response.data.items.payment_gateway?.map(item=> {
              return {
                  value: item?.name,
                  label: item?.name
              }
          })
          setPaymentOptionsLookup(options);
      }).catch(err=> {
          console.log(err);
      })
    }
  
  }, [subSlug]);

  const handleClick = async (e) => {

    e.preventDefault();

    const stripePromise = loadStripe(
      pubKey
    );

    const response = await fetch(
      serverConfig.app_base_url + "stripe/create_checkout_session_onetime",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: value,
          payment_session_id: paymentSessionId
        }),
      }
    );

    const { sessionId } = await response.json();
    setSessionId(sessionId)
    sessionStorage.setItem("sessionId", sessionId);
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({ sessionId });

    if (error) {
      console.error("Error redirecting to Checkout:", error);
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value, 'event');
    axios.post(serverConfig.api_base_url + "payment_gateway_details_get", {
      token: token, 
      org: selectedOrganization?.slug,
      gateway_name: e.target.value,
      pln_avail: subSlug
    }).then(response=> {
        const filteredValue = response.data.items.payment_attributes.filter(item => item?.name === "public_key");
        console.log(filteredValue, 'filtered_value');
        setValue(filteredValue[0]?.name);
        setPaymentSessionId(response?.data?.items?.payment_session_id);
        setPubKey(filteredValue[0]?.value);
    }).catch(err=>{
        console.log(err);
    })
  }

  useEffect(() => {
    const handleMouseDown = (event) => {
      console.log(event.target.classList, !event.target.classList.contains('btn-more-options'), 'classlist')
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.classList.contains('fa-ellipsis-vertical') && !event.target.classList.contains("action-option")) {
        setDisplayNone('none');
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
  
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [displayNone]);
       
       const sortDown=<i class="fa-solid fa-arrow-down-wide-short"></i>;
       const sortUp=<i class="fa-solid fa-arrow-up-short-wide"></i>;
      
       
        const dropdownItems = ['Updated', 'Option 2', 'Option 3']
        const sortingIcons= [sortDown,sortUp]
        const columns = [
        
                {
                    name: <div className='row-label pl-1'>Plan</div>,
                   
                    cell: (row) => <div className='pl-3'>
                                    {row.plan_name}
                                   </div>,
                    width: '20%',
                    sortable: false,
                   
                },
                {
                    name: <div className='row-label'>Price</div>,
                    selector:row=>row.type,
                    cell: (row) => <div className='pl-3'>
                                {row.price}
                            </div>,
                    sortable: true,
                
                    wrap:true
               },
               {
                    name: <div className='row-label'>Dashboards</div>,
                    selector:row=>row.type,
                    cell: (row) => 
                                    <div className='pl-3 overflow-ellipsis' title={row?.dashboard_names.join(", ")}>
                                    {row.dashboard_names.join(", ")}
                                    </div>,
                    sortable: true,
                    wrap:true,
                   
                },
                
                {
                    name: <div className='row-label'></div>,
                    selector:row=>row.type,
                    cell: (row) =>
                                <div>
                                {row?.is_paid === 0 ?
                                <div> 
                                <button className='sub-grp px-3 py-2' onClick={()=>{payRowClicked===row?setPayRowClicked(''):setPayRowClicked(row); setSubSlug(row?.pln_avail)}}>
                                    Pay
                                </button>
                                {row===payRowClicked && <div className='overlay'>
                                <form className='modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto' onSubmit={handleClick}>
                                <div className=" flex-grow overflow-y-auto ">
                                <div className="flex justify-between items-center gap-5">
                                <div style={{ fontSize: 16 }}>Pay</div>
                                </div>

                                <div className="mt-4">
                                <div className="flex flex-col gap-1 mt-3 mb-5 text-xs">
                                    <div>
                                    <label>
                                        Payment Options<span className="text-red-500">*</span>
                                    </label>
                                    <div style={{width: "30vw"}}>
                                    <select name="action" className='custom-metadata-input py-2 px-2 text-left mt-1' onChange={(e) => {handleChange(e);}} required>
                                        <option value="">Select Action Type</option> 
                                        {paymentOptionsLookup.length > 0 &&
                                            paymentOptionsLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                                        } 
                                        </select>
                                        </div>
                                    </div>                                  
                                </div>
                                </div>
                                </div>
                                {/* bottom buttom */}
                                <div className='spacing-div flex flex-row'>
                                <button type="button" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' disabled={loading} onClick={()=> {setPayRowClicked('');}}>Cancel</button>
                                <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' disabled={loading} style={loading ? {display: "flex", fontSize: "13px"} : {}}>Proceed {loading && <div className="signup-loader" style={{ marginLeft: '15px' }}></div>}</button>
                                </div>
                                </form>
                                </div>}
                                </div> : <>
                                <div className={'type-Client'}>
                                  <div>Subscribed</div>
                                  </div></> }
                                </div>,
                    sortable: true,
                    wrap:true,
                    right: true
                },
                             
            ]           

              return (
                <div className="overflow-x-auto">
                  <div className="p-3 table-custom mt-4 ">
                    <DataTable
                    
                     title={ <div className=" table-title rounded">
                     <div className=" mx-auto flex justify-between items-center">
                       <div className="flex space-x-4">
                        <h5 className='font-semibold'>Subscription</h5>
                       </div>               
                       <div className="flex items-center space-x-4 text-black">
                       {/* <div class="flex items-center gap-12 mt-4">
                            <div class="relative">
                                <input
                                type="text"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                class="w-30 md:w-48 h-8 md:h-10 pl-10 pr-4 rounded-xl border-1 border-gray-300  search-input"
                                />
                                <div class="absolute inset-y-0 left-0 pl-3 pb-3.5 flex items-center pointer-events-none">
                                <i class=" text-sm fas fa-search"></i>
                                </div>
                            </div>
                        </div>                          */}
                       </div>
                     </div>
                   </div>}
                      columns={columns}
                      data={tableData}
                      responsive={true}
                      pagination={true}
                      customStyles={{
                        rows: {
                          rows: {
                            className: 'custom-hover', 
                          }
                        },
                        cells: {
                          className: 'p-6',
                        },
                      }}
                   
                    />

        {alertModalShow && <>
          {showOkButton ? <Notification message={alertMessage} notificationType={'Error'} /> : <Notification message={alertMessage} notificationType={'Success'} />}
        </>}
                
        </div>
    </div>
              );
}

export default TableSubscription;