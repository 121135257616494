import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

const LineChart = ({
  item,
  data,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const svgRef = useRef();

  useEffect(() => {
    setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      // Set dimensions and margins for the chart
      const margin = { top: 70, right: 30, bottom: 120, left: 30 }; // Increase bottom margin for rotated labels and x-axis label
      const width = item?.width - margin.left - margin.right;
      const height = 250;

      // Clear any existing SVG elements before drawing
      d3.select(svgRef.current).selectAll("*").remove();

      // Create SVG element and append it to chart container
      const svg = d3
        .select(svgRef.current)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Create a tooltip div that is hidden by default
      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip-dashboards")
        .style("opacity", 0);

      // Set up x-axis and y-axis
      const x = d3.scaleTime().range([0, width]);

      const y = d3.scaleLinear().range([height, 0]);

      // Define the x and y domains
      x.domain(d3.extent(item.data, (d) => new Date(d[item?.x_cord])));
      y.domain([0, d3.max(item.data, (d) => d[item?.y_cord])]);

      // Add the x-axis
      svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .style("font-size", "14px")
        .call(
          d3
            .axisBottom(x)
            .ticks(d3.timeMonth.every(1))
            .tickFormat(d3.timeFormat("%b %Y"))
        )
        .call((g) => g.select(".domain"))
        .selectAll(".tick text")
        .style("fill", "#777")
        .attr("transform", "rotate(-45)") // Rotate labels
        .style("text-anchor", "end") // Adjust anchor
        .attr("dx", "-0.8em") // Adjust x position
        .attr("dy", "0.15em"); // Adjust y position

      svg.selectAll(".tick line").attr("stroke-opacity", 0);

      // Add the y-axis
      svg
        .append("g")
        .style("font-size", "14px")
        .call(d3.axisLeft(y).tickSize(0).tickPadding(10))
        .call((g) => g.select(".domain"))
        .selectAll(".tick text")
        .style("fill", "#777")
        .style("visibility", (d, i, nodes) => {
          if (i === 0) {
            return "hidden";
          } else {
            return "visible";
          }
        });

      // Add vertical grid lines
      svg
        .selectAll("xGrid")
        .data(x.ticks())
        .join("line")
        .attr("x1", (d) => x(d))
        .attr("x2", (d) => x(d))
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "#e0e0e0")
        .attr("stroke-width", 0.5);

      // Add horizontal grid lines
      svg
        .selectAll("yGrid")
        .data(y.ticks().slice(1))
        .join("line")
        .attr("x1", 0)
        .attr("x2", width)
        .attr("y1", (d) => y(d))
        .attr("y2", (d) => y(d))
        .attr("stroke", "#e0e0e0")
        .attr("stroke-width", 0.5);

      // Add Y-axis label
      svg
        .append("text")
        .attr("class", "y-axis-label")
        .attr("x", -height / 2)
        .attr("y", -50)
        .attr("text-anchor", "middle")
        .attr("transform", "rotate(-90)")
        //.attr("dy", "1em")
        .style("font-size", "14px")
        .style("fill", "#777")
        .style("font-family", "sans-serif")
        .text(`${item?.y_axis_label}`);

      // Add X-axis label
      svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom - 40) // Increase y to move it below tick text
        .style("text-anchor", "middle")
        .style("font-size", "14px")
        .style("fill", "#777")
        .style("font-family", "sans-serif")
        .text(`${item?.x_axis_label}`);

      // Define the line
      const line = d3
        .line()
        .x((d) => x(new Date(d[item?.x_cord])))
        .y((d) => y(d[item?.y_cord]));

      // Add the line path
      svg
        .append("path")
        .datum(item.data)
        .attr("fill", "none")
        .attr("stroke", item?.color || "steelblue")
        .attr("stroke-width", 1.5)
        .attr("d", line);

      // Add circles at data points for tooltips
      svg
        .selectAll("dot")
        .data(item.data)
        .enter()
        .append("circle")
        .style("opacity", 0)
        .attr("r", 5)
        .attr("cx", (d) => x(new Date(d[item?.x_cord])))
        .attr("cy", (d) => y(d[item?.y_cord]))
        .attr("fill", item?.color || "steelblue")
        .on("mouseover", function (event, d) {
          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip
            .html(`Date: ${d[item?.x_cord]}<br>Value: ${d[item?.y_cord]}`)
            .style("left", event.pageX + 5 + "px")
            .style("top", event.pageY - 28 + "px");
        })
        .on("mouseout", function (d) {
          tooltip.transition().duration(500).style("opacity", 0);
        });
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
    }
  }, [data, isLoading]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div className="mt-4"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div
          style={{
            overflow: "visible",
            margin: "5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg ref={svgRef}></svg>
        </div>
      )}
    </>
  );
};

export default LineChart;
