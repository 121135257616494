import React from "react";
import { useEffect, useState, useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Context } from "../../../contextApi/context.js";
import axios from "axios";
import { useNavigate } from "react-router";
import UserService from "../../../services/UserService.js";
import "../css/customerplan.css";
import Notification from "../../../common/notification/Notification.js";

const serverConfig = require("../../../config/server.js");

const CustomerPlanTable = () => {
  const navigate = useNavigate();

  const { selectedOrganization, isDarkTheme, setActiveSubmenuOption } =
    useContext(Context);

  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [formData, setFormData] = useState({});
  const [templateGroupList, setTemplateGroupList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [refreshTemplateGroupList, setRefreshTemplateGroupList] =
    useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [subscriptionPlanList, setSubscriptionPlanList] = useState([]);
  const [pricePlanList, setPricePlanList] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", {
        jwt: jwt,
        url: window.location.host,
      })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("token"));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    console.log("token", token);
    console.log(sessionStorage.getItem("token"));

    if (
      token !== null &&
      token !== undefined &&
      selectedOrganization?.slug !== undefined &&
      selectedOrganization?.slug !== null
    ) {
      console.log("selectedOrganization", selectedOrganization);
      console.log("token", token);

      axios
        .post(serverConfig.api_base_url + "organization_customer_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("organization_customer_list_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            console.log(items);
            if (items !== null && items !== undefined) {
              console.log(response.data.items);
              setCustomerList(response.data.items);
            } else {
              setCustomerList([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      axios
        .post(serverConfig.api_base_url + "cust_price_plan_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("cust_price_plan_list_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            console.log(items);
            if (items !== null && items !== undefined) {
              console.log(response.data.items);
              setPricePlanList(response.data.items);
            } else {
              setPricePlanList([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      axios
        .post(serverConfig.api_base_url + "cust_subscription_plan_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("cust_subscription_plan_list_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            console.log(items);
            if (items !== null && items !== undefined) {
              console.log(response.data.items);
              setSubscriptionPlanList(response.data.items);
            } else {
              // setSubscriptionPlanList([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));

      /* cust_subscription_plan_list_get */
      axios
        .post(serverConfig.api_base_url + "cust_plan_availability_list_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          console.log("cust_plan_availability_list_get response:", response);
          if (response.data.status === 200) {
            let items = response.data.items;
            if (items !== null && items !== undefined) {
              console.log(response.data.items);
              setTemplateGroupList(response.data.items);
              let appDataArray = response.data.items;
              let appData = [];

              console.log(appDataArray);
              appDataArray &&
                appDataArray.reverse().map((item, index) => {
                  {
                    item.enabled = item.status;
                  }
                  {
                    item.status = (
                      <div className="flex flex-row">
                        {item.status === 0 ? (
                          <div className="bg-bdr bg-bdr-peach">
                            <div>
                              <i class="fa-regular fa-user"></i>
                            </div>
                            <div>Disabled</div>
                          </div>
                        ) : (
                          <div className="bg-bdr bg-bdr-green">
                            <div>
                              <i class="fa-regular fa-user"></i>
                            </div>
                            <div>Enabled</div>
                          </div>
                        )}
                      </div>
                    );
                  }

                  let noOfActions = 1 + 1;
                  const Actions = () => (
                    <>
                      <Button
                        title={noOfActions > 2 ? "" : "Edit"}
                        variant="text"
                        onClick={() => handleShowEditTemplateModal(item)}
                      >
                        <i
                          className="fa-solid fa-pencil not-0"
                          aria-hidden="true"
                        ></i>{" "}
                        {noOfActions > 2 && <span>Edit</span>}
                      </Button>
                      <Button
                        title={noOfActions > 2 ? "" : "Delete"}
                        variant="text"
                        onClick={() =>
                          handleShowDeleteTemplateModal(
                            item?.templateGrp,
                            item?.display_name
                          )
                        }
                      >
                        <i
                          className="fa-solid fa-trash-can text-red-500"
                          aria-hidden="true"
                        ></i>{" "}
                        {noOfActions > 2 && <span>Delete</span>}
                      </Button>
                    </>
                  );
                  {
                    item.actions =
                      noOfActions > 2 ? (
                        <div className="text-right more-options-wrapper">
                          <button
                            type="button"
                            onClick={handleShowMoreOptions}
                            title="More"
                            className="btn-more-options"
                          >
                            <i className="fa-solid fa-ellipsis-vertical not-0"></i>
                          </button>
                          <div
                            className="more-options"
                            style={{ display: "none" }}
                          >
                            <Actions />
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-end gap-3">
                          <Actions />
                        </div>
                      );
                  }
                  appData.push(item);
                });
              console.log(appData);
              setTableData(appData);
            } else {
              setTableData([]);
              setTemplateGroupList([]);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            handleShowAlertModal(response.data.message, true);
            setTimeout(() => {
              handleCloseAlertModal();
            }, 6000);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, selectedOrganization, refreshTemplateGroupList]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  const sortDown = <i className="fa-solid fa-arrow-down-wide-short"></i>;
  const sortUp = <i className="fa-solid fa-arrow-up-short-wide"></i>;
  const sortingIcons = [sortDown, sortUp];

  const columns = [
    {
      name: <div className="font-bold text-sm">Customer</div>,
      selector: (row) => <div className="pl-2">{row?.customer}</div>,
      //   sortable: true,
      wrap: true,
      width: "20%",
    },
    {
      name: <div className="font-bold text-sm">Subscription Plan</div>,
      selector: (row) => <div className="pl-2">{row?.subscription_plan}</div>,
      //   sortable: true,
      wrap: true,
      width: "20%",
    },
    {
      name: <div className="font-bold text-sm">Is Active</div>,
      selector: (row) => (
        <div className="pl-3">{row?.is_active === 1 ? "Yes" : "No"}</div>
      ),
      //   sortable: true,
      wrap: true,
    },
    {
      name: <div className="font-bold text-sm">Start Date</div>,
      selector: (row) => {
        const date = new Date(row?.start_date);
        const formattedDate = date.toLocaleDateString("en-GB"); // This formats the date as DD-MM-YYYY
        return <div className="pl-4">{formattedDate}</div>;
      },
      wrap: true,
    },
    {
      name: <div className="font-bold text-sm">End Date</div>,
      selector: (row) => {
        const date = new Date(row?.end_date);
        const formattedDate = date.toLocaleDateString("en-GB"); // This formats the date as DD-MM-YYYY
        return <div className="pl-4">{formattedDate}</div>;
      },
      wrap: true,
    },
    {
      name: <div className="font-bold text-sm">Price</div>,
      selector: (row) => (
        <div className="pl-3">
          {row?.price ? `${row?.currency} ${row?.price}` : "-"}
        </div>
      ),
      //   sortable: true,
      wrap: true,
    },
    {
      name: <div className="font-bold text-sm">Override Price</div>,
      selector: (row) => (
        <div className="pl-3">
          {row?.overide_price ? row?.overide_price : "-"}
        </div>
      ),
      //   sortable: true,
      wrap: true,
    },
    {
      name: <div className="font-bold text-sm pr-2">Actions</div>,
      cell: (row) => (
        <div className="flex justify-end space-x-3 mr-2">
          <button
            type="button"
            onClick={() => handleShowEditTemplateModal(row)}
            title="Edit"
            className="btn-edit"
          >
            <i className="fa-solid fa-pencil"></i>
          </button>
          {/* <button
          type="button"
          onClick={() => handleShowDeleteTemplateModal(row)}
          title="Delete"
          className="btn-delete"
        >
          <i className="fas fa-trash-can text-red-500"></i>
        </button> */}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleEnable = (item, slug, enable) => {
    console.log(console.log(item));
    console.log(formData);
    console.log(enable);
    axios
      .post(serverConfig.api_base_url + "dwh_template_group_update", {
        token: token,
        org: selectedOrganization?.slug,
        items: { ...item, status: enable === 0 ? 0 : 1 },
        templateGrp: slug,
      })
      .then((response) => {
        console.log("dwh_template_group_update response", response);
        if (response.data.status === 200) {
          console.log("success");
          setRefreshTemplateGroupList(!refreshTemplateGroupList); // to refresh template group list after is_enabled is set 0/1
          handleShowAlertModal(
            "Category " + (enable === 0 ? "disabled" : "enabled")
          );
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  const [createChildGroupTypeOption, setCreateChildGroupTypeOption] =
    useState("");
  const handleCreateChildGroupTypeOption = (event) => {
    console.log(event.target.value);
    setCreateChildGroupTypeOption(event.target.value);
  };

  //Create
  const [createDashboardModalShow, setCreateDashboardModalShow] =
    useState(false);
  const handleCloseDas = () => {
    setCreateDashboardModalShow(false);
    setFormData({});
    setCreateChildGroupTypeOption("");
  };
  const handleShowCreateTemplateModal = () => {
    setCreateDashboardModalShow(true);
  };

  const handleCreateTemplateSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    axios
      .post(serverConfig.api_base_url + "cust_plan_availability_create", {
        token: token,
        org: selectedOrganization?.slug,
        items: { ...formData },
      })
      .then((response) => {
        console.log("cust_plan_availability_create :", response);
        if (response.data.status === 200) {
          setFormData({});
          setRefreshTemplateGroupList(!refreshTemplateGroupList);
          handleCloseDas();
          handleShowAlertModal("Plan added successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  //Edit
  const [templateSlug, setTemplateSlug] = useState("");
  const [dashboardName, setDashboardName] = useState("");
  const [editTemplateModalShow, setEditTemplateModalShow] = useState(false);
  const [editStartDate, setEditStartDate] = useState();

  const handleCloseEditTemplateModal = () => {
    setEditTemplateModalShow(false);
    setTemplateSlug("");
    setDashboardName("");
    //setFormMessage({});
    setFormData({});
    setCreateChildGroupTypeOption("");
  };
  const handleShowEditTemplateModal = (details) => {
    console.log(details);
    setFormData(details);
    setEditTemplateModalShow(true);
    const formattedDate = details.start_date.split(" ")[0];
    setEditStartDate(formattedDate);
    console.log(formattedDate);
  };

  const handleUpdateTemplate = (event) => {
    event.preventDefault();

    const payload = {
      token: token,
      org: selectedOrganization?.slug,
      planavail: formData.planavail,
      items: {
        cust: formData.cust,
        subplan: formData.subs,
        start_date: formData.start_date,
        end_date: formData.end_date,
        is_active: formData.is_active,
        priceplan: formData.priplan,
        overide_price: formData.overide_price,
      },
    };

    console.log(payload, "PAYLOAD");

    axios
      .post(
        serverConfig.api_base_url + "cust_plan_availability_update",
        payload
      )
      .then((response) => {
        console.log("cust_subscription_plan_edit:", response);
        if (response.data.status === 200) {
          handleShowAlertModal("Plan updated successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          handleCloseEditTemplateModal();
          setRefreshTemplateGroupList(!refreshTemplateGroupList);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  //Delete
  const [deleteTemplateModalShow, setDeleteTemplateModalShow] = useState(false);
  const handleCloseDeleteTemplateModal = () => {
    setDeleteTemplateModalShow(false);
    setTemplateSlug("");
    setDashboardName("");
    setFormData({});
  };

  const handleShowDeleteTemplateModal = (details) => {
    setDeleteTemplateModalShow(true);
    console.log(details);
    setFormData(details);
    setDashboardName(details.subscription_name);
  };

  const handleDeleteTemplate = (event) => {
    event.preventDefault();

    const { subscription_name, description } = formData;

    const payload = {
      token: token,
      org: selectedOrganization?.slug,
      subs: formData.subs,
      items: {
        name: subscription_name,
        description: description,
      },
    };
    axios
      .post(serverConfig.api_base_url + "canvas_dashboard_delete", payload)
      .then((response) => {
        console.log("dwh_template_group_delete:", response);
        if (response.data.status === 200) {
          handleShowAlertModal("Template Category deleted successfully");
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
          handleCloseDeleteTemplateModal();
          setRefreshTemplateGroupList(!refreshTemplateGroupList);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  return (
    <div className="overflow-x-auto">
      <div className="p-3 table-custom table-mapmetadata mt-4">
        <div className="flex justify-between items-center space-x-4">
          <h1 className="font-semibold text-2xl mt-4 mb-4">
            Customer Plan Availability
          </h1>
          {tableData.length > 0 && (
            <div className="flex items-center space-x-4 text-black">
              <button
                type="button"
                className="sub-grp px-3 py-2"
                onClick={handleShowCreateTemplateModal}
              >
                <i className="fa-solid fa-plus mr-2" aria-hidden="true"></i> Add
                Plan
              </button>
            </div>
          )}
        </div>
        {tableData.length > 0 ? (
          <DataTable
            columns={columns}
            data={tableData}
            responsive={true}
            pagination={tableData.length > 10 ? true : false}
            highlightOnHover
            customStyles={{
              rows: {
                style: {
                  minHeight: "48px",
                  border: "2px solid var(--neutral-400)",
                  borderRadius: "10px",
                  marginTop: "10px",
                },
                highlightOnHoverStyle: {
                  color: isDarkTheme ? "#ffffff" : "#000000",
                  backgroundColor: isDarkTheme ? "#222222" : "#EFF3F9",
                  outline: "none",
                },
              },
            }}
            style={{
              height: "100%",
            }}
          />
        ) : (
          <div className="flex flex-col h-3/4 items-center justify-center">
            <div className="mb-8">
              {/* <img
                src={require("./../../Asset/Images/no-records.png")}
                alt="No records"
              /> */}
            </div>
            <p>
              <b>This Organisation has no Plans</b>
            </p>
            <p className="text-sm">
              Add first plan for {selectedOrganization?.name}
            </p>
            <div className="mt-5">
              <button
                className="sub-grp px-3 py-2"
                onClick={handleShowCreateTemplateModal}
              >
                New Plan
              </button>
            </div>
          </div>
        )}

        {createDashboardModalShow && (
          <div className="overlay">
            <div className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2">
              <div className="flex justify-between items-center gap-5">
                <div style={{ fontSize: 16 }}>Customer Plan Availability</div>
              </div>
              <form
                className="flex flex-col justify-between w-full"
                onSubmit={handleCreateTemplateSubmit}
              >
                <div className="mt-5 mb-5">
                  <div>
                    <label>Customer</label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={formData.cust || ""} // Bind the selected value from formData
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            cust: e.target.value, // Set the cust value as cust
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                      >
                        <option value="">Select Customer</option>
                        {customerList.map((customer, index) => (
                          <option key={index} value={customer.cust}>
                            {customer.owner_name} ({customer.org_name})
                          </option>
                        ))}
                      </select>
                    </div>

                    <label className="mt-2">
                      Subscription Plan
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={formData.subplan || ""} // Bind the selected value from formData
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            subplan: e.target.value, // Set the subs value as subplan
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        <option value="">Select Subscription Plan</option>
                        {subscriptionPlanList.map((plan, index) => (
                          <option key={index} value={plan.subs}>
                            {plan.subscription_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Radio buttons and Date pickers in the same row */}
                    <div className="flex items-center gap-5 mt-2">
                      {/* Radio buttons */}
                      <div>
                        <label className="mt-2">
                          Is Active?<span className="text-red-500 mr-6">*</span>
                        </label>
                        <div className="flex gap-3 mt-2">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="is_active"
                              value="1"
                              className="mt-2"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  is_active: e.target.value,
                                })
                              }
                              required
                            />
                            <span className="ml-1 mt-2">Yes</span>
                          </label>
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="is_active"
                              value="0"
                              className="mt-2"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  is_active: e.target.value,
                                })
                              }
                              required
                            />
                            <span className="ml-1 mt-2">No</span>
                          </label>
                        </div>
                      </div>

                      {/* Start date picker */}
                      <div className="flex flex-col pt-1 ml-6">
                        <label className="ml-6 mt-4">Start Date :</label>
                        <input
                          type="date"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              start_date: e.target.value,
                            });
                            const endDateInput =
                              document.getElementById("end-date");
                            if (endDateInput) {
                              endDateInput.min = e.target.value;
                            }
                          }}
                          min={new Date().toISOString().split("T")[0]}
                          style={{ width: "150px" }}
                          className="custom-input py-2 px-2 mt-2 ml-2"
                          required
                        />
                      </div>

                      {/* End date picker */}
                      <div className="flex flex-col pt-1">
                        <label className="ml-4 mt-4">End Date :</label>
                        <input
                          type="date"
                          id="end-date"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              end_date: e.target.value,
                            })
                          }
                          min={
                            formData.start_date ||
                            new Date().toISOString().split("T")[0]
                          }
                          style={{ width: "150px" }}
                          className="custom-input py-2 px-2 mt-2 ml-2"
                          required
                        />
                      </div>
                    </div>

                    <label className="mt-4">
                      Price Plan<span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={formData.priceplan || ""} // Bind the selected value from formData
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            priceplan: e.target.value, // Set the pricepln value as priceplan
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        <option value="">Select Price Plan</option>
                        {pricePlanList.map((plan, index) => (
                          <option key={index} value={plan.pricepln}>
                            {plan.name} - {plan.currency} {plan.price}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-5 mt-4">
                      <label>Override Price</label>
                      <input
                        type="number"
                        placeholder="Override Price"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            overide_price: e.target.value,
                          })
                        }
                        className="custom-input py-2 px-2 mt-1 w-full"
                      />
                    </div>
                  </div>
                </div>

                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                    onClick={handleCloseDas}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {editTemplateModalShow && (
          <div className="overlay">
            <form
              className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col w-1/2"
              onSubmit={handleUpdateTemplate}
            >
              <div className=" flex-grow overflow-y-auto ">
                <div className="flex justify-between items-center gap-5">
                  <div style={{ fontSize: 16 }}>Edit Plan</div>
                </div>

                <div className="mt-5 mb-5">
                  <div>
                    <label>Customer</label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={formData.cust || ""} // Bind the selected value from formData
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            cust: e.target.value, // Set the cust value as cust
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                      >
                        <option value="">Select Customer</option>
                        {customerList.map((customer, index) => (
                          <option key={index} value={customer.cust}>
                            {customer.owner_name} ({customer.org_name})
                          </option>
                        ))}
                      </select>
                    </div>

                    <label className="mt-2">
                      Subscription Plan
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={formData.subs || ""} // Bind the selected value from formData
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            subs: e.target.value, // Set the subs value as subplan
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        <option value="">Select Subscription Plan</option>
                        {subscriptionPlanList.map((plan, index) => (
                          <option key={index} value={plan.subs}>
                            {plan.subscription_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Radio buttons and Date pickers in the same row */}
                    <div className="flex items-center gap-5 mt-2">
                      {/* Radio buttons */}
                      <div>
                        <label className="mt-2">
                          Is Active?<span className="text-red-500 mr-6">*</span>
                        </label>
                        <div className="flex gap-3 mt-2">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="is_active"
                              value="1"
                              checked={
                                formData.is_active === "1" ||
                                formData.is_active === 1
                              }
                              className="mt-2"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  is_active: e.target.value,
                                })
                              }
                              required
                            />
                            <span className="ml-1 mt-2">Yes</span>
                          </label>
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="is_active"
                              value="0"
                              checked={
                                formData.is_active === "0" ||
                                formData.is_active === 0
                              }
                              className="mt-2"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  is_active: e.target.value,
                                })
                              }
                              required
                            />
                            <span className="ml-1 mt-2">No</span>
                          </label>
                        </div>
                      </div>

                      {/* Start date picker */}
                      <div className="flex flex-col pt-1 ml-6">
                        <label className="ml-6 mt-4">Start Date :</label>
                        <input
                          type="date"
                          value={
                            formData.start_date
                              ? formData.start_date.split(" ")[0]
                              : ""
                          }
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              start_date: e.target.value,
                            })
                          }
                          min={editStartDate}
                          style={{ width: "150px" }}
                          className="custom-input py-2 px-2 mt-2 ml-2"
                          required
                        />
                      </div>

                      {/* End date picker */}
                      <div className="flex flex-col pt-1">
                        <label className="ml-4 mt-4">End Date :</label>
                        <input
                          type="date"
                          value={
                            formData.end_date
                              ? formData.end_date.split(" ")[0]
                              : ""
                          }
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              end_date: e.target.value,
                            })
                          }
                          min={
                            formData.start_date ||
                            new Date().toISOString().split("T")[0]
                          }
                          style={{ width: "150px" }}
                          className="custom-input py-2 px-2 mt-2 ml-2"
                          required
                        />
                      </div>
                    </div>

                    <label className="mt-4">
                      Price Plan<span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center gap-3 mt-1">
                      <select
                        value={formData.priplan || ""} // Bind the selected value from formData
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            priplan: e.target.value, // Set the pricepln value as priceplan
                          })
                        }
                        className="custom-input py-2 px-2 text-left min-w-fit w-1/3"
                        required
                      >
                        <option value="">Select Price Plan</option>
                        {pricePlanList.map((plan, index) => (
                          <option key={index} value={plan.pricepln}>
                            {plan.name} - {plan.price}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-5 mt-4">
                      <label>Override Price</label>
                      <input
                        type="number"
                        value={formData.overide_price || ""}
                        placeholder="Override Price"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            overide_price: e.target.value,
                          })
                        }
                        className="custom-input py-2 px-2 mt-1 w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacing-div">
                <button
                  type="button"
                  className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                  onClick={handleCloseEditTemplateModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        )}

        {deleteTemplateModalShow && (
          <>
            <div className="overlay">
              <form
                style={{ width: "35vw" }}
                className="modalPopup rounded-3xl py-7 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2"
                onSubmit={handleDeleteTemplate}
              >
                <div className=" flex-grow overflow-y-auto ">
                  <div className="flex justify-between items-center gap-5">
                    <div style={{ fontSize: 14 }}>
                      <b>Delete Dashboard</b>
                    </div>
                  </div>
                  <div className="mt-4 text-xs mb-4">
                    <p>
                      Are you sure you want to delete <b>{dashboardName}</b> ?
                    </p>
                  </div>
                </div>
                {/* bottom buttom */}
                <div className="spacing-div">
                  <button
                    type="button"
                    className="cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs"
                    onClick={handleCloseDeleteTemplateModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="delete-obj px-4 py-2 rounded-lg mt-2 text-xs"
                  >
                    Delete Chart
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {alertModalShow && (
          <>
            {showOkButton ? (
              <Notification message={alertMessage} notificationType={"Error"} />
            ) : (
              <Notification
                message={alertMessage}
                notificationType={"Success"}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerPlanTable;
