import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import * as d3 from "d3";

const AddMarkers = ({
  markerData,
  showMarkers,
  value,
  groupKey,
  label,
}) => {
  const map = useMap();

  const colorScale = d3.scaleOrdinal(d3.schemeCategory10);
  const uniqueGroups = [...new Set(markerData.map((d) => d[groupKey]))];
  colorScale.domain(uniqueGroups);

  useEffect(() => {
    if (showMarkers && Array.isArray(markerData)) {
      // Remove existing markers
      map.eachLayer((layer) => {
        if (layer instanceof L.CircleMarker) {
          map.removeLayer(layer);
        }
      });

      // Add new circular markers
      markerData.forEach((stateData) => {
        const { latitude, longitude } = stateData;
        const markerColor = colorScale(stateData[groupKey]);

        // Get the dynamic label and value from stateData using the label and value keys
        const labelText = stateData[label] && stateData[label].trim() !== '' ? stateData[label] : null;
        const valueText = stateData[value] && stateData[value].trim() !== '' ? stateData[value] : null;

        // Create a circle marker
        const marker = L.circleMarker([latitude, longitude], {
          radius: 5, // Adjust the radius as needed
          fillColor: markerColor,
          color: "black", // Border color
          weight: 2, // Border weight
          opacity: 1,
          fillOpacity: 0.8,
        }).addTo(map);

        // Construct the tooltip text only if label or value is present
        let tooltipText = '';
        if (labelText) {
          tooltipText += `<b>${labelText}</b><br>`;
        }
        if (valueText) {
          tooltipText += `${valueText}`;
        }

        // Bind the tooltip only if there's content to display
        if (tooltipText.trim() !== '') {
          marker.bindTooltip(tooltipText, {
            sticky: true,
          });
        }
      });
    }
  }, [map, markerData, showMarkers, value, groupKey, label]);

  return null;
};


const Map = ({
  data,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [noData, setNoData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const title = item.title || "Map";
  const valueKey = item.value;
  const groupKey = item.group;
  const [showMarkers, setShowMarkers] = useState(true);

  const toggleMarkers = () => setShowMarkers(!showMarkers);

  const [mapCenter, setMapCenter] = useState([37.8, -96]); // Default center
  const [zoomLevel, setZoomLevel] = useState(5); // Default zoom level

  console.log(data, "DATA");
  console.log(item, "ITEM");

  useEffect(() => {
    if (data && data.length > 0) {
      setNoData("");
      setIsLoading(false);
    } else {
      setNoData("No data available");
    }
  }, [data]);

  useEffect(() => {
    if (item.data === "fetching") {
      setIsLoading(true);
      return;
    }
  }, [data]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <div>
      {noData ? (
        <>
          <div className="chart-title">{noData}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="chart-title">{item?.title} </div>
          ) : (
            <div className="mt-4"></div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                    console.log(item, "formData");
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div className="p-4">
          <MapContainer
            center={mapCenter}
            zoom={zoomLevel}
            style={{ height: 400, width: "100%" }}
            attributionControl={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {/* Render markers */}
            {Array.isArray(data) && data.length > 0 && (
              <AddMarkers
                markerData={data}
                showMarkers={showMarkers}
                title={title}
                value={item?.value}
                groupKey={item?.group}
                label={item?.label}
              />
            )}
          </MapContainer>
        </div>
      )}
    </div>
  );
};

export default Map;
