import React, { useState, useEffect } from 'react';
//import { projectData } from '../../Asset/Data/projectData';
//import { permissionsData } from '../../Asset/Data/permissionsData';
import axios from 'axios';
import UserService from '../../../services/UserService';
import { useContext } from 'react';
import { Context } from '../../../contextApi/context';
import { MultiSelect } from "react-multi-select-component";
import "../css/customers.css";
import { MDBSelect } from 'mdb-react-ui-kit';

const serverConfig = require("../../../config/server.js");

function CreateCustomer({ setEditRowClicked, setModalClosed, modalClosed, setAddCustomer }) {

  const { selectedOrganization } = useContext(Context);
  const [inputs, setInputs] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tab, setTab] = useState('Customer Info');
  const [memberTab, setMemberTab] = useState(0)
  const [cToken, setCToken] = useState(sessionStorage.getItem('token'));
  const [rolesList, setRolesList] = useState([]);
  const [userName, setUserName] = useState('');
  const [orgName, setOrgName] = useState('');
  const [logoName, setLogoName] = useState('');
  const [logoBase64, setLogoBase64] = useState('');
  const [message, setMessage] = useState('');


  const [email, setEmail] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [orgError, setOrgError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitInviteCustomer = (event) => {
    event.preventDefault();

    if (validateName() && validateEmail() && validateOrgName()) {

      axios.post(serverConfig.api_base_url + "organization_invite", {
        token: cToken,
        url: window.location.host,
        org: selectedOrganization?.slug,
        name: userName,
        email_id: email,
        organization_name: orgName,
        logo: logoName,
        logo_base64: logoBase64
      })
        .then(response => {
          setLoading(true);
          console.log("organization_invite response:", response);
          if (response.data.status === 200) {
            setLoading(false);
            setShowSuccessModal(true);
          }
          else if (response.data.status === 501) {
            setLoading(false);
            setMessage(response.data.message);
          }
          else if (response.data.status === 301) {
            handleLogout();
          } else {
            setLoading(false);
            setMessage(response.data.message);
          }
        })
        .catch(err => console.log("error is", err));
    }
  }

  const validateEmail = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      setEmailError('Enter a valid email');
      return false;
    }
    else if (email === '') {
      setEmailError('Email is required');
      return false;
    }
    setEmailError('');
    return true;
  }

  const validateName = () => {
    if (userName === '') {
      setNameError('Name is required');
      return false;
    }
    setNameError('');
    return true;
  }

  const validateOrgName = () => {
    if (orgName === '') {
      setOrgError('Organization Name is required');
      return false;
    }
    setOrgError('');
    return true;
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log(name, value);

    if (name === 'user_name') {
      setUserName(value);
    }
    else if (name === 'email_id') {
      setEmail(value);
    }
    else if (name === 'organization_name') {
      setOrgName(value);
    }
  }


  const getToken = () => {
    //console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token", { jwt: jwt, url: window.location.host })
      .then(response => {
        console.log('user_get_token response', response);
        if (response.data.status === 200) {
          sessionStorage.setItem('token', response.data.token);
          setCToken(response.data.token);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        } else if (response.data.status === 301) {
          handleLogout();
        }
      })
      .catch(err => console.log("error is", err));
  }

  useEffect(() => {
    //console.log(sessionStorage.getItem('token'));
    if (sessionStorage.getItem('token') === null) {
      getToken();
    }
  }, []);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const getBase64 = (file, e) => {
    console.log(file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      if(e.target.name === 'logo') {
        setLogoName(file.name);
        setLogoBase64(reader.result);
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  return (

    <div className='edit-user  rounded-3xl py-4 px-9 relative  mx-28  my-5 text-custom text-sm flex justify-between flex-col items-center'>
      <div className='overflow-y-auto '>

        {loading === true ?
          <>
            <div className='loading-div flex flex-col'>
              <img src={require(`./../assets/invitingUser.png`)} alt='' className='loading-img' />
              <h1 className='invite-text'>Sending Invite...</h1>
            </div>
            <div className='flex flex-row justify-end'>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => { setAddCustomer(false); setShowSuccessModal(false); }}>Close</button>
            </div>
          </> :
          showSuccessModal === true ? <>


            <div className='loading-div flex flex-col'>
              <img src={require(`./../assets/uploadSuccess.png`)} alt='' className='loading-img' />
              <h1 className='invite-text'>Invite Sent!</h1>
            </div>
            <div className='flex flex-row justify-end'>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => { setAddCustomer(false); setModalClosed(!modalClosed); }}>Done</button>
            </div>


          </> : <>
            <div className='flex items-center gap-8'>



              <div className='flex flex-col team-label'>

                <div className="flex space-x-4">
                  <button className={`px-6 py-3 ml-6 cursor-pointer ${memberTab === 0 ? 'tab form' : 'tab-unactive'}`} onClick={() => { setTab('Customer Info'); setMemberTab(0); }}>Customer Info</button>
                </div>

                <h5 className='mt-4 font-semibold ml-8'>Customer Info</h5>

                <div className='flex flex-row'>
                  <div className='flex flex-col'>
                    <div className='flex flex-col gap-1 mt-8 text-xs input-field mr-8 ml-8'>
                      <label>Name<span className=' text-red-500'>*</span></label>
                      <input type='text' name='user_name' value={userName}
                        onChange={handleChange} placeholder='Name' className='invite-field custom-input py-2 px-2 text-left mt-1' required />

                    </div>
                    {nameError !== '' ? <p className="error-text  ml-8">{nameError}</p> : <></>}
                  </div>


                  <div className='flex flex-col'>
                    <div className='flex flex-col gap-1 mt-8 text-xs email-field input-field mr-2 ml-4'>
                      <label>Email ID<span className=' text-red-500'>*</span></label>
                      <input type='email' name='email_id' value={email}
                        onChange={handleChange} placeholder='Email' className='invite-field custom-input py-2 px-2 text-left mt-1' required />

                    </div>
                    {emailError !== '' ? <p className="error-text mt-12 ml-8">{emailError}</p> : <></>}
                  </div>
                </div>

                <div className='flex flex-row'>
                  <div className='flex flex-col'>
                    <div className='flex flex-col gap-1 mt-8 text-xs input-field input-field mr-8 ml-8'>
                      <label>Organization Name<span className=' text-red-500'>*</span></label>
                      <input type='text' name='organization_name' value={orgName}
                        onChange={handleChange} placeholder='Organization Name' className='invite-field custom-input py-2 px-2 text-left mt-1' required />

                    </div>
                    {orgError !== '' ? <p className="error-text mt-2 ml-8">{orgError}</p> : <></>}
                  </div>

                  <div className='flex flex-col gap-1 mt-8 text-xs input-field mr-2 ml-4'>
                    <label className='mb-1'>Logo</label>
                    <div className="newOrg flex items-center">
                      {logoBase64 && <img src={logoBase64} alt="logo" width="70" className='w-auto max-w-full rounded-md' style={{maxHeight: "60px"}} />}
                      <div className={logoBase64 ? "flex flex-col ml-3" : "flex flex-col"}>
                        <label className="newOrg custom-file-upload">
                          <input  type='file' value="" onChange={(e)=>  {getBase64(e.target.files[0], e)}} name="logo" accept='.jpg, .jpeg, .png' className='custom-input py-2 px-2 text-left mt-1'/>
                          {logoBase64 ? <>Choose Another File</> : <>Choose File</>}
                        </label>
                        <small className='newOrg text-slate-400 mt-2'>File Size Limit: 1Mb</small>
                      </div>
                    </div>

                  </div>

                </div>

                <div className='flex flex-row ml-10'>

                  {message !== '' ? <div className={"mt-4 text-sm font-medium text-red-500"}>{message}</div> : <></>}

                </div>

              </div>

            </div>

            <div className='flex flex-row justify-end mr-8'>
              <button type="submit" className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={() => { setAddCustomer(false); }}>Cancel</button>
              <button type="submit" className='btn-popup px-4 py-2 rounded-lg mt-2 text-sm text-medium' onClick={submitInviteCustomer}>Invite Customer</button>


            </div>
          </>
        }

      </div>


    </div>

  )
}

export default CreateCustomer;